import React, { useId } from "react";
import { Badge, Button, Checkbox, Divider, Flex, Grid, Group, Input, Modal, SegmentedControl, Text, Tooltip } from "@mantine/core";
import { IconFileDollar } from "@tabler/icons";
import { ColumnType, ITableRef, Table } from "../table";
import { LbfLimit } from "../../apis";
import { ILimit } from "../../interfaces/ILimit";
import { IReference } from "../../models/General";
import { IGeneral } from "../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import { BuyerLedForm } from "../buyer-led-limit/form";
import { useDebouncedValue } from "@mantine/hooks";
import { IMaskInput } from "react-imask";

const anchorData = [
  {
    name: "Бүгд",
    code: "",
    color: "yellow",
  },
  {
    name: "Тийм",
    color: "green",
    code: `${true}`,
  },
  {
    name: "Үгүй",
    color: "pink",
    code: `${false}`,
  },
];

export function SupplierLedLimit() {
  const ref = React.useRef<ITableRef>(null);
  const [action, setAction] = React.useState<string[]>([]);
  const { lbfProgramStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [regNumber, setRegNumber] = React.useState("");
  const [debouncedRegNumber] = useDebouncedValue(regNumber, 600);
  const [clientName, setClientName] = React.useState("");
  const [debouncedClientName] = useDebouncedValue(clientName, 600);
  const [clientCode, setClientCode] = React.useState("");
  const [debouncedClientCode] = useDebouncedValue(clientCode, 600);
  const [programName, setProgramName] = React.useState("");
  const [debouncedProgramName] = useDebouncedValue(programName, 600);
  const [anchor, setAnchor] = React.useState("");
  const [debouncedAnchor] = useDebouncedValue(anchor, 600);
  const [programStatus, setProgramStatus] = React.useState("");
  const [debouncedProgramStatus] = useDebouncedValue(programStatus, 600);
  const id = useId();

  const columns = useHeader({
    lbfProgramStatus,
    onClick: (key, record) => {
      switch (key) {
        case "limit": {
          setAction(["limit", record.clientRegNumber]);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const onChangeReg = (e: string) => {
    if (e.length === 7 || e.length === 0) {
      setRegNumber(e);
    }
  };

  return (
    <>
      <Grid>
        <Grid.Col span={3}>
          <Input.Wrapper
            id={id}
            onChange={(e: any) => {
              onChangeReg(e.target.value);
            }}>
            <Input<any>
              component={IMaskInput}
              mask="0000000"
              id={id}
              iconWidth={80}
              placeholder="хайх"
              style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
              icon={
                <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                  <Text size="sm" weight={450} c="#44566C">
                    {"ТТД"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={3}>
          <Input
            value={clientName}
            iconWidth={150}
            placeholder="хайх"
            maxLength={45}
            style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
            icon={
              <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                <Text size="sm" weight={450} c="#44566C">
                  {"Харилцагч нэр"}
                </Text>
                <Text>:</Text>
              </Group>
            }
            onChange={(e) => {
              setClientName(e.currentTarget.value);
            }}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Input
            value={clientCode}
            iconWidth={80}
            placeholder="хайх"
            maxLength={45}
            style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
            icon={
              <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                <Text size="sm" weight={450} c="#44566C">
                  {"CIF"}
                </Text>
                <Text>:</Text>
              </Group>
            }
            onChange={(e) => {
              setClientCode(e.currentTarget.value);
            }}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Input
            value={programName}
            iconWidth={140}
            placeholder="хайх"
            maxLength={100}
            style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
            icon={
              <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                <Text size="sm" weight={450} c="#44566C">
                  {"Хөтөлбөр нэр"}
                </Text>
                <Text>:</Text>
              </Group>
            }
            onChange={(e) => {
              setProgramName(e.currentTarget.value);
            }}
          />
        </Grid.Col>

        <Grid.Col span={3}>
          <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap" key={1}>
            <Text size="sm" weight={450} c="#44566C">
              Anchor
            </Text>
            <SegmentedControl
              onChange={(e) => setAnchor(e)}
              value={anchor}
              data={(anchorData || []).map((item: any, index: number) => {
                return {
                  value: item.code || "",
                  label: (
                    <Badge variant="dot" color={item.color} style={{ border: "none" }} key={`${index}`}>
                      {item.name}
                    </Badge>
                  ),
                };
              })}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap" key={1}>
            <Text size="sm" weight={450} c="#44566C">
              Хөтөлбөр статус
            </Text>
            <SegmentedControl
              onChange={(e) => setProgramStatus(e)}
              value={programStatus}
              data={[{ name: "Бүгд", code: "", color: "yellow" }, ...(lbfProgramStatus || [])].map((item: IReference, index: number) => {
                return {
                  value: item.code || "",
                  label: (
                    <Badge variant="dot" color={item.color} style={{ border: "none" }} key={`${index}`}>
                      {item.name}
                    </Badge>
                  ),
                };
              })}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}></Grid.Col>
        <Grid.Col span={3}></Grid.Col>

        <Grid.Col span={12}>
          <Divider />
        </Grid.Col>
      </Grid>
      <Table
        ref={ref}
        name="lbf-supplier-led-limit.list"
        columns={columns}
        filters={{
          lbfProductType: "SUPPLIER_LED",
          clientCode: debouncedClientCode,
          clientRegNumber: debouncedRegNumber,
          clientName: debouncedClientName,
          isAnchor: debouncedAnchor,
          lbfProgramName: debouncedProgramName,
          lbfProgramStatus: debouncedProgramStatus,
        }}
        loadData={(data) => LbfLimit.list(data!)}
      />
      <Modal opened={action[0] === "limit"} onClose={() => setAction([])} withCloseButton={false} centered size="60%">
        <BuyerLedForm action={action} onCancel={onCancel} title="Байгууллагын лимит" subTitle="Supplier - LED байгууллагын лимитийн дэлгэрэнгүй" />
      </Modal>
    </>
  );
}

const useHeader = ({ onClick, lbfProgramStatus }: { onClick: (key: string, record: ILimit) => void; lbfProgramStatus: IReference[] }): ColumnType<ILimit>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Tooltip label="Лимит" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("limit", record)}>
              <IconFileDollar />
            </Button>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
  },
  {
    title: "CIF",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.clientCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.clientRegNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Харилцагчийн нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.business?.profileName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#3e166e">
            {record?.business?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Supplier",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Checkbox
          checked={!record.isBuyer ? true : false}
          label={
            <Text size="sm" weight={500} c="#44566C">
              {!record.isBuyer ? "Тийм" : "Үгүй"}
            </Text>
          }
          size="sm"
          onChange={() => {}}
        />
      );
    },
  },
  {
    title: "Buyer",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Checkbox
          checked={record.isBuyer ? true : false}
          label={
            <Text size="sm" weight={500} c="#44566C">
              {record.isBuyer ? "Тийм" : "Үгүй"}
            </Text>
          }
          size="sm"
          onChange={() => {}}
        />
      );
    },
  },
  {
    title: "Anchor",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Checkbox
          checked={record.isAnchor ? true : false}
          label={
            <Text size="sm" weight={500} c="#44566C">
              {record.isAnchor ? "Тийм" : "Үгүй"}
            </Text>
          }
          size="sm"
          onChange={() => {}}
        />
      );
    },
  },
  {
    title: "Хөтөлбөрийн нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.lbfProgramName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#3e166e">
            {record?.lbfProgramRefCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Хөтөлбөр статус",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          color={(lbfProgramStatus || []).find((item: IReference) => item.code === record.lbfProgramStatus)?.color}>
          {(lbfProgramStatus || []).find((item: IReference) => item.code === record.lbfProgramStatus)?.name}
        </Badge>
      );
    },
  },
];
