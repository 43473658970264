import {
  IconBuildingBank,
  IconClipboardList,
  IconCreditCard,
  IconExchange,
  IconFileSymlink,
  IconLicense,
  IconClipboard,
  IconReceiptRefund,
  IconRotateClockwise2,
  IconUserCheck,
  IconUsers,
  IconWallet,
  IconUserCircle,
  IconBrandFirebase,
  IconId,
  IconRefreshDot,
  IconChecklist,
  IconCashBanknote,
  IconCalendarStats,
  IconBellRinging,
  IconCurrencyDollar,
  IconFileFunction,
  IconReport,
  IconClipboardData,
  IconVideo,
  IconChartPie,
} from "@tabler/icons";

export interface NavigationItem {
  key?: string;
  title: string;
  description?: string;
  icon: React.FC;
  color: string;
  link: string;
  disabled?: boolean;
  children?: NavigationItem[];
  parent?: NavigationItem;
  view?: any;
}

const navigationData: NavigationItem[] = [
  // {
  //   key: "LBF",
  //   title: "Зээл суурьтай санхүүжилт",
  //   description: `Уламжлалт зээлийн лимит бүтээгдэхүүнд суурилсан санхүүжилт шийдвэрлэх`,
  //   icon: IconFileSymlink,
  //   color: "red",
  //   link: "/cf",
  //   children: [
  //     // {
  //     //   title: "Дашбоард",
  //     //   icon: IconChartPie,
  //     //   color: "indigo",
  //     //   link: "/cf/dashboard",
  //     //   // LBF_DASHBOARD
  //     // },
  //     {
  //       title: "Санхүүжилтийн хүсэлт",
  //       icon: IconLicense,
  //       color: "indigo",
  //       link: "/cf/funding-request",

  //       // navigation tavina   LBF_REQUEST_BUYER isView ||  LBF_REQUEST_SUPPLIER isView
  //     },
  //     {
  //       title: "Олгосон зээлийн данс",
  //       icon: IconLicense,
  //       color: "indigo",
  //       link: "/cf/account",
  //       // navigation tavina LBF_ACCOUNT_BUYER || LBF_ACCOUNT_SUPPLIER
  //     },
  //     {
  //       title: "Санхүүжилтийн хөтөлбөр",
  //       icon: IconLicense,
  //       color: "indigo",
  //       link: "/cf/funding-program",
  //       // navigation tavina   LBF_PROGRAM_MANAGEMENT_BL isView ||  LBF_PROGRAM_MANAGEMENT_SL isView
  //     },
  //     {
  //       title: "Санхүүжилтийн бүтээгдэхүүн",
  //       icon: IconLicense,
  //       color: "indigo",
  //       link: "/cf/product",
  //       // navigation tavina LBF_PRODUCT_MANAGEMENT_BL isView || LBF_PRODUCT_MANAGEMENT_SL isView
  //     },
  //     {
  //       title: "Шугамын лимит",
  //       icon: IconReceiptRefund,
  //       color: "indigo",
  //       link: "/cf/limit",
  //       // navigation tavina LBF_LINE_LIMIT_USAGE_BL  || LBF_LINE_LIMIT_USAGE_SL
  //     },
  //     {
  //       title: "Авлага барьцаалсан лимит",
  //       icon: IconReceiptRefund,
  //       color: "indigo",
  //       link: "/cf/receivable-limit",
  //       // navigation tavina  LBF_LIMIT_USAGE_BL LBF || LBF_LIMIT_USAGE_SL LBF
  //     },
  //     // {
  //     //   title: "Буцаан дуудсан",
  //     //   icon: IconReceiptRefund,
  //     //   color: "indigo",
  //     //   link: "/cf/refund",
  //     // },
  //     {
  //       title: "Лавлах мэдээлэл",
  //       icon: IconReport,
  //       color: "indigo",
  //       link: "/cf/reference",
  //       // navigation tavina  LBF LBF_REFERENCE
  //       key: "LBF_REFERENCE",
  //     },
  //   ],
  //   disabled: false,
  // },
  {
    key: "SCF",
    title: "SCF санхүүжилт",
    description: `B2B харилцаан дахь батлагдсан нэхэмжлэх барьцаалж санхүүжилт шийдвэрлэх`,
    icon: IconLicense,
    color: "#3e166e",
    link: "/scf",
    children: [
      {
        title: "Дашбоард",
        icon: IconChartPie,
        color: "indigo",
        link: "/scf/dashboard",
        //
      },
      {
        title: "SCF хүсэлт",
        icon: IconLicense,
        color: "indigo",
        link: "/scf/request",
        // navigation tavina  SCF_REQUEST_MANAGEMENT_BL isView || SCF_REQUEST_MANAGEMENT_SL isView
      },
      {
        title: "Олголтын гүйлгээ",
        icon: IconBuildingBank,
        color: "indigo",
        link: "/scf/funding-instruction",
        // navigation tavina SCF_FUNDING_INSTRUCTION_MANAGEMENT_BL isView || SCF_FUNDING_INSTRUCTION_MANAGEMENT_SL isView
      },
      {
        title: "Олгосон санхүүжилт",
        icon: IconBuildingBank,
        color: "indigo",
        link: "/scf/funding-provided",
        // navigation tavina  SCF_COMPLETED_REQUEST_BL || SCF_COMPLETED_REQUEST_SL
      },
      // {
      //   title: "Боломжит нэхэмжлэх",
      //   icon: IconFileInvoice,
      //   color: "indigo",
      //   link: "/scf/finance-invoice-potential",
      // },
      {
        title: "Эргэн төлөлтүүд",
        icon: IconCreditCard,
        color: "indigo",
        link: "/scf/finance-refund-paid",
        // navigation tavina SCF_REPAYMENT_SL || SCF_REPAYMENT_BL
      },
      {
        title: "Буцаан дуудсан",
        icon: IconReceiptRefund,
        color: "indigo",
        link: "/scf/finance-invoice-refund",
        // navigation tavina SCF_REPAYMENT_SL || SCF_REPAYMENT_BL
      },
      {
        title: "Лимит ашиглалт",
        icon: IconRefreshDot,
        color: "indigo",
        link: "/scf/limit",
        // navigation tavina SCF_PROGRAM_MANAGEMENT_BL isView || SCF_PROGRAM_MANAGEMENT_SL  isView
      },
    ],
  },
  {
    key: "CLIENT",
    title: "Харилцагч удирдлага",
    description: "Санхүүжилтийн хөтөлбөрт хамрагдах корпорэйт, SME харилцагчдын бүртгэл, тохиргоо",
    icon: IconUserCircle,
    color: "#3e166e",
    link: "/customer",
    children: [
      {
        title: "Анкор Корпорэйт",
        icon: IconClipboardList,
        color: "indigo",
        link: `/customer/anchor`,
        // navigation tavina CLIENT_ANCHOR
        key: "CLIENT_ANCHOR",
      },
      {
        title: "SME харилцагч",
        icon: IconClipboard,
        color: "indigo",
        link: "/customer/sme-customer",
        // navigation tavina CLIENT_SME
        key: "CLIENT_SME",
      },
      {
        title: "Хүсэлт",
        icon: IconChecklist,
        color: "indigo",
        link: `/customer/request`,
        // navigation tavina CLIENT_FINANCE_ROLE_MANAGEMENT isView
        key: "CLIENT_FINANCE_ROLE_MANAGEMENT",
      },
      {
        title: "B2B Данс",
        icon: IconWallet,
        color: "indigo",
        link: "/customer/b2b-accounting",
        key: "CLIENT_ACCOUNT",
        // navigation tavina CLIENT_ACCOUNT
      },
    ],
  },
  {
    key: "USER",
    title: "Хэрэглэгч удирдлага",
    description: `Funder системийг ашиглах хэрэглэгч, хэрэглэгчийн эрхийн тохиргоо хийн хэрэглэгчийг удирдах`,
    icon: IconUsers,
    color: "indigo",
    link: "/user",
    children: [
      {
        title: "Системийн хэрэглэгчид",
        icon: IconUsers,
        color: "indigo",
        link: "/user/user-list",
        view: "",
        // navigation tavina USER_MANAGEMENT isView
      },
      {
        title: "Эрхийн тохиргоо",
        icon: IconUserCheck,
        color: "indigo",
        link: "/user/role-list",
        // navigation tavina USER_ROLE_MANAGEMENT
      },
      // { title: "Хяналтын дүрэм", icon: IconChecklist, color: "indigo", link: "/user/rule-list" },
      // {
      //   title: "Системийн лог",
      //   icon: IconUserCheck,
      //   color: "indigo",
      //   link: "/user/system-log",
      // },
    ],
    disabled: false,
  },
  {
    key: "PRODUCT",
    title: "Санхүүжилтийн бүтээгдэхүүн",
    description: "Supply Chain Finance-ийн шинэ бүтээгдэхүүн үйлчилгээ бүртгэх, санхүүжилтийн бүтээгдэхүүн удирдах",
    icon: IconBrandFirebase,
    color: "yellow",
    link: "/scf-dcf-product",
    children: [
      {
        title: "Дашбоард",
        icon: IconChartPie,
        color: "indigo",
        link: "/scf-dcf-product/dashboard", //SCF_PRODUCT_DASHBOARD
        key: "SCF_PRODUCT_DASHBOARD",
      },
      {
        title: "Buyer LED SCF",
        icon: IconClipboardData,
        color: "indigo",
        link: "/scf-dcf-product/dcf-product",
        // navigation tavina SCF_PRODUCT_MANAGEMENT_BL
        key: "SCF_PRODUCT_MANAGEMENT_BL",
      },
      {
        title: "Supplier LED SCF",
        icon: IconClipboardList,
        color: "indigo",
        link: "/scf-dcf-product/scf-product",
        // navigation tavina SCF_PRODUCT_MANAGEMENT_SL
        key: "SCF_PRODUCT_MANAGEMENT_SL",
      },
      {
        title: "Данс тохиргоо",
        icon: IconBuildingBank,
        color: "indigo",
        link: "/scf-dcf-product/accounting",
        // navigation tavina SCF_PRODUCT_ACCOUNT_DISBURSEMENT || SCF_PRODUCT_ACCOUNT_FEE || SCF_PRODUCT_ACCOUNT_REPAYMENT
      },
      {
        title: "SCF/DCF санхүүжилт",
        icon: IconFileFunction,
        color: "indigo",
        link: "/scf-dcf-product/finance",
        // navigation tavina SCF_PRODUCT_REQUEST
        key: "SCF_PRODUCT_REQUEST",
      },
    ],
  },
  {
    key: "PROGRAM",
    title: "Санхүүжилтийн хөтөлбөр",
    description: "Харилцагч бизнесүүддээ санхүүжилтийн хөтөлбөр бүртгэх, тохируулга хийх, идэвхжүүлэх",
    icon: IconId,
    color: "blue",
    link: "/scf-dcf",
    children: [
      {
        title: "Дашбоард",
        icon: IconChartPie,
        color: "indigo",
        link: "/scf-dcf/dashboard", //SCF_PROGRAM_DASHBOARD
        key: "SCF_PROGRAM_DASHBOARD",
      },
      {
        title: "Buyer LED хөтөлбөр",
        icon: IconClipboardData,
        color: "indigo",
        link: "/scf-dcf/dcf-program",
        // navigation tavina  code => SCF_PROGRAM_MANAGEMENT_BL isView
        key: "SCF_PROGRAM_MANAGEMENT_BL",
      },
      {
        title: "Supplier LED хөтөлбөр",
        icon: IconClipboardList,
        color: "indigo",
        link: "/scf-dcf/scf-program",
        // navigation tavina  code => SCF_PROGRAM_MANAGEMENT_SL isView
        key: "SCF_PROGRAM_MANAGEMENT_SL",
      },
      {
        title: "Хөтөлбөрийн ашиглалт",
        icon: IconExchange,
        color: "indigo",
        link: "/scf-dcf/usage",
        // navigation tavina  code => SCF_PROGRAM_LIMIT_USAGE_BL || SCF_PROGRAM_LIMIT_USAGE_SL
      },
      {
        title: "Дэд Лимит ашиглалт",
        icon: IconRotateClockwise2,
        color: "indigo",
        link: "/scf-dcf/limit",
        // navigation tavina  SCF_PROGRAM_SUB_LIMIT_USAGE_SL || SCF_PROGRAM_SUB_LIMIT_USAGE_BL
      },
      {
        title: "Лавлах мэдээлэл",
        icon: IconReport,
        color: "indigo",
        link: "/scf-dcf/reference",
        // navigation tavina  SCF_PROGRAM_REFERENCE
        key: "SCF_PROGRAM_REFERENCE",
      },
    ],
  },
  {
    key: "COLLECTION",
    title: "Эргэн төлөлт, хяналт",
    description: "Санхүүжилтийн эргэн төлөлт, хугацаа хэтрэлтийг хянах, сануулах болон буцаан дуудах",
    icon: IconReceiptRefund,
    color: "orange",
    link: "/collection",
    children: [
      {
        title: "Дашбоард",
        icon: IconChartPie,
        color: "indigo",
        link: "/collection/dashboard", //
      },
      {
        title: "Анхаарах харилцагч",
        icon: IconCashBanknote,
        color: "indigo",
        link: "/collection/observation",
        // navigation tavina  code => COLLECTION_OBSERVATION
        key: "COLLECTION_OBSERVATION",
      },
      {
        title: "Хугацаа хэтэрсэн",
        icon: IconCalendarStats,
        color: "indigo",
        link: "/collection/payment-overdue",
        // navigation tavina  code => COLLECTION_OVERDUE
        key: "COLLECTION_OVERDUE",
      },
      {
        title: "Төлөлт сануулга",
        icon: IconBellRinging,
        color: "indigo",
        link: "/collection/payment-reminder",
        // navigation tavina  code => COLLECTION_REMINDER ||  COLLECTION_REMINDER_EMAIL ||COLLECTION_REMINDER_AUTO_REMINDED
      },
      {
        title: "Амлалт тохирсон",
        icon: IconRotateClockwise2,
        color: "indigo",
        link: "/collection/payment-condition",
        // navigation tavina  code => COLLECTION_COMPROMISE
        key: "COLLECTION_COMPROMISE",
      },
      {
        title: "Тооцоо нийлэх",
        icon: IconCurrencyDollar,
        color: "indigo",
        link: "/collection/payment-settlement",
        // navigation tavina  code => COLLECTION_SETTLEMENT
        key: "COLLECTION_SETTLEMENT",
      },
    ],
  },
  {
    key: "video",
    title: "Заавар видео",
    description: `Funder системийг ашиглан Supply Chain Finance үйлчилгээ үзүүлэхтэй холбоотой заавар, гарын авлагууд`,
    icon: IconVideo,
    color: "indigo",
    link: "/manual",
    children: [],
  },
];

const navigationDataMappedByLink = navigationData.reduce((acc, item) => {
  acc[item.link] = item;
  if (item.children) {
    item.children.forEach((child) => {
      acc[child.link] = {
        ...child,
        parent: item,
      };
    });
  }

  return acc;
}, {} as Record<string, NavigationItem>);

const navigationDataMappedByParentLink = navigationData.reduce((acc, item) => {
  if (item.children) {
    acc[item.link] = item.children;
  }

  return acc;
}, {} as Record<string, NavigationItem[]>);

export { navigationData, navigationDataMappedByLink, navigationDataMappedByParentLink };
