import { Flex, Group, Text } from "@mantine/core";
import { useNavigate, useParams } from "react-router";
import { ColumnType, Table } from "../table";
import { IPayments } from "../../interfaces/IPayments";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";

export function AccountBuyerHistory({ data }: { data: IPayments }) {
  const params = useParams();
  const navigate = useNavigate();

  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          navigate(`/cf/funding-request/${params.type || "SUPPLIER"}/request`);
          break;
        }
      }
    },
  });

  return (
    <div style={{ marginTop: 10 }}>
      <Table name="buyer-led-program-credit.list" columns={columns} dataSource={data} pagination={false} />
    </div>
  );
}

const useHeader = ({ onClick }: { onClick: (key: string, record: any) => void }): ColumnType<any>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
  },
  {
    title: "Гүйлгээний огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.refCode || "-"}
          </Text>
          <Text size="sm" weight={500} c="#3e166e">
            {dateSecFormat(record?.createdAt)}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Харьцсан данс",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.payerAccName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#3e166e">
            {record?.payerAccNum || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Төлсөн дүн",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Group position="right" mr={10}>
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record?.amount || 0)}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Төлвөл зохих",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Group position="right" mr={10}>
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record?.firstAmount || 0)}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Үлдэгдэл",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Group position="right" mr={10}>
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record?.lastAmount || 0)}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Гүйлгээний утга",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.description || "-"}
        </Text>
      );
    },
  },
];
