import React from "react";
import { Paper, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { IconMailOpened } from "@tabler/icons";
import { RequestList } from "../../../components/request/list";

export function RequestTab() {
  const [tabKey, setTabKey] = React.useState<string | null>("NETWORK");

  const titles = (val: string) => {
    switch (val) {
      case "NETWORK": {
        return "Хүсэлт";
      }
    }
  };

  const title = (val: string) => {
    switch (val) {
      case "NETWORK": {
        return "Хүсэлтийн жагсаалт";
      }
    }
  };

  const subTitles = (val: string) => {
    switch (val) {
      case "NETWORK": {
        return "Банктай санхүүжилтийн хөтөлбөрийн хамтрах сонирхолоо илэрхийлсэн байгууллагууд .";
      }
    }
  };

  const breadcrumbs = useBreadcrumb(titles(tabKey as string));

  return (
    <PageLayout title={title(tabKey as string)} subTitle={subTitles(tabKey as string)} breadcrumb={breadcrumbs}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs value={tabKey} onTabChange={setTabKey}>
          <Tabs.List>
            <Tabs.Tab value="NETWORK" icon={<IconMailOpened color={tabKey === "NETWORK" ? "#3e166e" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Хүсэлт
              </Text>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="NETWORK">{<RequestList />}</Tabs.Panel>
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Харилцагч удирдлага" },
  {
    label: type,
  },
];
