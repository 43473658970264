import React, { useId } from "react";
import { ISupplier } from "../../interfaces/ISupplier";
import { ColumnType, Table } from "../table";
import { RequestApi } from "../../apis";
import { ActionIcon, Badge, Button, Divider, Flex, Grid, Group, Input, Select, Text, Tooltip } from "@mantine/core";
import { IconListDetails, IconX } from "@tabler/icons";
import { useNavigate } from "react-router";
import { tugrug } from "../../utils";
import { useDebouncedValue } from "@mantine/hooks";
import { IMaskInput } from "react-imask";
import useSwr from "swr";
import { useSelector } from "react-redux";
import { IAuth } from "../../interfaces/IAuth";
import { IPermissions } from "../../interfaces/IPermissions";

export function ScfSupplierList() {
  const navigate = useNavigate();
  const id = useId();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [regNumber, setRegNumber] = React.useState("");
  const [debouncedRegNumber] = useDebouncedValue(regNumber, 600);
  const [business, setBusiness] = React.useState("");
  const [debouncedBusiness] = useDebouncedValue(business, 600);
  const [program, setProgram] = React.useState("");
  const [debouncedProgram] = useDebouncedValue(program, 600);

  const columns = useHeader({
    user,
    onClick: (key, record) => {
      switch (key) {
        case "request": {
          navigate(`/scf/request/SUPPLIER/${record.id}/1`);
          break;
        }
      }
    },
  });

  const onChangeReg = (e: string) => {
    if (e.length === 7 || e.length === 0) {
      setRegNumber(e);
    }
  };

  const { data } = useSwr(
    `/api/scf/request/supplier_led/business_select`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_SL" && c.isView).length > 0) {
        let res = await RequestApi.business("");
        return res;
      }
      return [];
    },
    {},
  );

  const { data: programData } = useSwr(
    `/api/scf/request/supplier_led/program_select/`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_SL" && c.isView).length > 0) {
        let res = await RequestApi.program("");
        return res;
      }
      return [];
    },
    {},
  );

  return (
    <>
      <Grid>
        <Grid.Col span={3}>
          <Input.Wrapper
            id={id}
            onChange={(e: any) => {
              onChangeReg(e.target.value);
            }}>
            <Input<any>
              component={IMaskInput}
              mask="0000000"
              id={id}
              iconWidth={80}
              placeholder="хайх"
              style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
              icon={
                <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                  <Text size="sm" weight={450} c="#44566C">
                    {"ТТД"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
            />
          </Input.Wrapper>
        </Grid.Col>
        {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_SL" && c.isView).length > 0 && (
          <Grid.Col span={3}>
            <Select
              value={business}
              data={(data || []).map((c: any) => ({
                value: c.id,
                label: c.profileName,
              }))}
              label=""
              iconWidth={150}
              placeholder="сонгох "
              icon={
                <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                  <Text size="sm" weight={450} c="#44566C">
                    {"Бизнесийн нэр"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
              onChange={(e) => {
                setBusiness(e as string);
              }}
              rightSection={
                <ActionIcon onClick={() => setBusiness("")}>
                  <IconX size={15} stroke={1.2} />
                </ActionIcon>
              }
            />
          </Grid.Col>
        )}
        {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_SL" && c.isView).length > 0 && (
          <Grid.Col span={3}>
            <Select
              value={program}
              data={(programData || []).map((c: any) => ({
                value: c.id,
                label: c.name,
              }))}
              label=""
              iconWidth={160}
              placeholder="сонгох "
              icon={
                <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                  <Text size="sm" weight={450} c="#44566C">
                    {"Хөтөлбөрийн нэр"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
              onChange={(e) => {
                setProgram(e as string);
              }}
              rightSection={
                <ActionIcon onClick={() => setProgram("")}>
                  <IconX size={15} stroke={1.2} />
                </ActionIcon>
              }
            />
          </Grid.Col>
        )}

        <Grid.Col span={12}>
          <Divider mb={5} />
        </Grid.Col>
      </Grid>

      <Table
        name="scf.funding.supplier.list"
        columns={columns}
        filters={{
          programId: debouncedProgram,
          businessId: debouncedBusiness,
          businessRegNumber: debouncedRegNumber,
        }}
        loadData={(data) => RequestApi.listSupplier(data!)}
      />
    </>
  );
}

const useHeader = ({ onClick, user }: { onClick: (key: string, record: ISupplier) => void; user: any }): ColumnType<ISupplier>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_SL" && c.isView).length > 0 ? (
            <Tooltip label="Хүсэлтүүд" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("request", record)}>
                <IconListDetails />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Хүсэлтүүд" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconListDetails />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Хүсэлтийн код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.requestRefCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүсэлт илгээсэн бизнес",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 200 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record?.requestedBusiness?.refCode || "-"}, {record?.requestedBusiness?.regNumber || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#3e166e">
                {record?.requestedBusiness?.profileName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хөтөлбөрийн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#3e166e">
                {record?.name || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Партнерийн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.anchor?.partner?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#3e166e">
                {record?.anchor?.partner?.businessName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Анкор бизнес",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.anchor?.businessName || "-"}
              </Text>
            </Flex>
            <Badge size="md" radius="sm" variant="outline">
              {record?.anchor?.refCode || "-"}
            </Badge>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Ашигласан лимит дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.utilizedAmount || 0)}</Group>
        </Text>
      );
    },
  },
  {
    title: "Ашиглах боломжтой",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.availableAmount || 0)}</Group>
        </Text>
      );
    },
  },
  {
    title: "Хүсэлтийн дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.requestedAmount || 0)}</Group>
        </Text>
      );
    },
  },
  {
    title: "Хүсэлт тоо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.requestCount || "0"}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх тоо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.invCount || "0"}
        </Text>
      );
    },
  },
  {
    title: "Батлагдсан лимит",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.programLimit || 0)}</Group>
        </Text>
      );
    },
  },
];
