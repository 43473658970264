/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { createStyles, LoadingOverlay, Paper, Text, ThemeIcon } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import BackgroundPattern from "../../assets/bg-pattern.png";

const useStyles = createStyles((theme, _params: { color: string }) => ({
  card: {
    minHeight: 240,
    position: "relative",
    backgroundColor: "#ffffff19",
    cursor: "pointer",
    border: 0,
    borderRadius: 6,
    overflow: "hidden",
    transition: "transform 150ms ease, box-shadow 100ms ease",
    padding: theme.spacing.xl * 2,
    paddingLeft: theme.spacing.xl * 1.5,

    "&:hover": {
      boxShadow: theme.shadows.xs,
      transform: "scale(1.05)",
    },
  },
  background: {
    backgroundRepeat: "repeat",
    position: "absolute",
    width: "100%",
    height: "100%",
    "&:hover": {
      transform: "scale(1.4)",
    },
  },
  container: {
    position: "relative",
  },
  disable: {
    position: "relative",
    backgroundColor: "#ffffff00",
    border: 0,
    borderRadius: 0,
    overflow: "hidden",
    transition: "transform 150ms ease, box-shadow 100ms ease",
    padding: theme.spacing.xl * 2,
    paddingLeft: theme.spacing.xl * 1.5,

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: 6,
    },
  },
}));

export function GridCard({ data }: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [item, setItem] = React.useState<string>("");
  const { classes } = useStyles({
    color: data.color,
  });

  React.useEffect(() => {
    setLoading(true);
    if (item !== undefined) {
      navigate(item);
    }
    setLoading(false);
  }, [item, navigate]);

  // console.log(data);

  if (data.disabled) {
    return (
      <div>
        <Paper withBorder className={classes.disable}>
          <ThemeIcon size="xl" radius={4} variant="gradient" gradient={{ deg: 0,from: "#34105e", to: "#3e166e" }}>
            <data.icon size={28} stroke={1.5} />
          </ThemeIcon>
          <Text size="lg" color={"white"} weight={500} mt="md">
            {data.title}
          </Text>
          <Text size="sm" mt="sm" color="white">
            {data.description}
          </Text>
        </Paper>
      </div>
    );
  }

  const onChange = (data: string) => {
    switch (data) {
      case "/scf": {
        setItem("/scf/dashboard");
        break;
      }
      case "/cf": {
        setItem("/cf/funding-request");
        break;
      }
      case "/collection": {
        setItem("/collection/dashboard");
        break;
      }
      case "/scf-dcf": {
        setItem("/scf-dcf/dashboard");
        break;
      }
      case "/scf-dcf-product": {
        setItem("/scf-dcf-product/dashboard");
        break;
      }
      case "/customer": {
        setItem("/customer/anchor");
        break;
      }
      case "/user": {
        setItem("/user/user-list");
        break;
      }
    }
  };

  return (
    <Link to={item} onClick={() => onChange(data.link)} className={classes.container}>
      <LoadingOverlay visible={loading} />
      <img src={BackgroundPattern} alt="" className={classes.background} />
      <Paper withBorder className={classes.card}>
        <ThemeIcon size="xl" radius={4} variant="gradient" gradient={{ deg: 0, from: "#34105e", to: "#3e166e" }}>
          <data.icon size={28} stroke={1.5} />
        </ThemeIcon>
        <Text size="lg" color={"white"} weight={500} mt="md">
          {data.title}
        </Text>
        <Text size="sm" mt="sm" color="white">
          {data.description}
        </Text>
      </Paper>
    </Link>
  );
}
