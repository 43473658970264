import React from "react";
import { LoadingOverlay, Paper, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";
import { ProductsApi } from "../../../apis";
import { useNavigate } from "react-router";
import { ISupplierLedFormData, ScfProductMainForm } from "../../../components/supplier-product/main-form";
import { IconCashBanknote, IconFileText, IconMoneybag } from "@tabler/icons";

export function SupplierProductNew() {
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb();
  const [tabIndex] = React.useState("1");
  const [loading, setLoading] = React.useState<boolean>(false);

  const onSubmit = async (data: ISupplierLedFormData, type: boolean) => {
    setLoading(true);
    try {
      const res = await ProductsApi.create(data);
      if (type) {
        navigate(`/scf-dcf-product/scf-product/edit/${res.id}/2`);
        Message.success("Бүтээгдэхүүнийг амжилттай үүсгэсэн.");
      } else {
        navigate("/scf-dcf-product/scf-product");
        Message.success("Бүтээгдэхүүнийг амжилттай хадгалсан.");
      }
    } catch (err) {
      setLoading(false);
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <PageLayout title="Supplier LED-SCF бүтээгдэхүүн" subTitle="Supplier LED-SCF бүтээгдэхүүнийг шинээр бүртгэх" breadcrumb={breadcrumbs} bgColor="#F8F9FA">
      <Paper radius="sm" p="md" withBorder>
        <Tabs defaultValue="1" value={tabIndex}>
          <LoadingOverlay visible={loading} />
          <Tabs.List>
            <Tabs.Tab value="1" icon={<IconFileText color={tabIndex === "1" ? "#3e166e" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Үндсэн мэдээлэл
              </Text>
            </Tabs.Tab>
            <Tabs.Tab value="2" icon={<IconMoneybag color={tabIndex === "2" ? "#9F20F7" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Санхүүжилт нөхцөл
              </Text>
            </Tabs.Tab>
            <Tabs.Tab value="3" icon={<IconCashBanknote color={tabIndex === "3" ? "#3e166e" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Шимтгэл хураамж
              </Text>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="1">
            <ScfProductMainForm onSubmit={onSubmit} action={["create"]} />
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Санхүүжилтийн бүтээгдэхүүн",
  },
  {
    to: "/scf-dcf-product/scf-product",
    label: "Supplier LED бүтээгдэхүүний жагсаалт",
  },
  {
    label: "Шинэ",
  },
];
