import React, { useEffect } from "react";
import { Divider, LoadingOverlay, SegmentedControl } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import HttpHandler from "../../../utils/http-handler";
import { Message } from "../../../utils/message";
import useSwr from "swr";
import { ProgramDashboardApi } from "../../../apis";
import { useDebouncedValue } from "@mantine/hooks";
import { DatePicker } from "@mantine/dates";
import { IconCalendarEvent } from "@tabler/icons";
import { ScfDcfDashboardStatic } from "./static";
import { ScfDcfDashboardReal } from "./real";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router";

export function ScfDcfDashboard() {
  const breadcrumbs = useBreadcrumb();
  const nowMoment = new Date();
  const [value, setValue] = React.useState("real");
  const [date, setDate] = React.useState<Date | null>(null);
  const [debouncedDate] = useDebouncedValue(date, 600);
  const [limitDate, setLimitDate] = React.useState<Date | null>(null);
  const [limitDebounced] = useDebouncedValue(limitDate, 600);
  const [pieDate, setPieDate] = React.useState<Date | null>(null);
  const [pieDebounced] = useDebouncedValue(pieDate, 600);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/scf-dcf") && !location.pathname.includes("/scf-dcf/dashboard")) {
      navigate("/scf-dcf/dashboard", { replace: true });
    }
  }, [location, navigate]);

  const { data, isLoading } = useSwr(
    `/api/program/dashboard/${debouncedDate ? debouncedDate : nowMoment}/${limitDebounced ? limitDebounced : nowMoment}/${
      pieDebounced ? pieDebounced : nowMoment
    }`,
    async () => {
      try {
        let res = await ProgramDashboardApi.get({
          date: debouncedDate ? debouncedDate.toISOString() : nowMoment.toISOString(),
          limitDate: limitDebounced ? limitDebounced.toISOString() : nowMoment.toISOString(),
          pieDate: pieDebounced ? pieDebounced.toISOString() : nowMoment.toISOString(),
        });
        return res;
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
      }
    },
  );

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return (
    <>
      <PageLayout
        title="Тоон тойм"
        subTitle=""
        breadcrumb={breadcrumbs}
        extra={[
          <DatePicker
            key={1}
            value={date ? date : nowMoment}
            maxDate={nowMoment || date}
            onChange={setDate}
            placeholder="Өдөр сонгох"
            minDate={dayjs(nowMoment || date)
              .subtract(90, "days")
              .toDate()}
            rightSection={<IconCalendarEvent color="gray" />}
          />,
        ]}>
        <Divider />
        <>
          <SegmentedControl
            mt={3}
            mb={3}
            value={value}
            onChange={setValue}
            w={200}
            data={[
              { label: "Static", value: "static" },
              { label: "Real", value: "real" },
            ]}
            color="cyan[4]"
          />
          {value === "static" ? (
            <ScfDcfDashboardStatic nowMoment={nowMoment} />
          ) : (
            <ScfDcfDashboardReal
              data={data}
              limitDate={limitDate}
              setLimitDate={setLimitDate}
              nowMoment={nowMoment}
              pieDate={pieDate}
              setPieDate={setPieDate}
            />
          )}
        </>
      </PageLayout>
    </>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Санхүүжилтийн хөтөлбөр",
  },
  {
    label: "Дашбоард",
  },
];
