import { Badge, Paper, Text, createStyles } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { ColumnType, Table } from "../../../components/table";
import { IGeneral } from "../../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import { IReference } from "../../../models/General";
import { Link } from "react-router-dom";

export function ScfDcfReference() {
  const { classes } = useStyles();
  const breadcrumbs = useBreadcrumb();
  const { references } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({ classes });

  return (
    <PageLayout title="Лавлах мэдээлэл" subTitle="Лавлах мэдээллийн төрөлийн жагсаалт" breadcrumb={breadcrumbs}>
      <Paper radius="sm" p="md" withBorder>
        <Table name="scf-program-reference.list" columns={columns} dataSource={references || []} pagination={false} />
      </Paper>
    </PageLayout>
  );
}

const useHeader = ({ classes }: { classes: any }): ColumnType<IReference>[] => [
  {
    title: "#",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
  },
  {
    title: "Түвшин",
    sorter: true,
    dataIndex: "level",
    render: (record) => {
      return (
        <Link to={`/scf-dcf/reference/${record?.order}`}>
          <Badge className={classes.reference} size="md" radius="sm" variant="outline">
            {record.code}
          </Badge>
        </Link>
      );
    },
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "name",

    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.description || "-"}
        </Text>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Санхүүжилтийн хөтөлбөр",
  },
  {
    label: "Лавлах мэдээлэл",
  },
];

const useStyles = createStyles((theme) => ({
  reference: {
    "&:hover": {
      color: "#3e166e",
    },
  },
}));
