import React, { useRef } from "react";
import useSwr from "swr";
import { LoadingOverlay, Modal, Paper, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { useNavigate, useParams } from "react-router";
import { ProductsApi } from "../../../apis";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";
import { ITableRef } from "../../../components/table";
import { ISupplierLedFormData, ScfProductMainForm } from "../../../components/supplier-product/main-form";
import { ISupplierLedFinanceData, ScfProductFinanceForm } from "../../../components/supplier-product/finance-form";
import { ScfProductFeeForm } from "../../../components/supplier-product/fee-form";
import { RequestPinForm } from "../../../components/buyer-product/request-pin-form";
import { IconCashBanknote, IconFileText, IconMoneybag } from "@tabler/icons";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function SupplierProductEdit() {
  const navigate = useNavigate();
  const params = useParams();
  const { id, index } = params;
  const breadcrumbs = useBreadcrumb();
  const ref = useRef<ITableRef>(null);
  const [tabIndex, setTabIndex] = React.useState(index || "1");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [action, setAction] = React.useState<string[]>([]);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { data, mutate } = useSwr(`/api/supplier-led/product/${id}`, async () => await ProductsApi.get(`${id}`), {
    fallback: { data: null },
  });

  const onMainSubmit = async (data: ISupplierLedFormData, type: boolean) => {
    setLoading(true);

    try {
      await ProductsApi.update(params.id as string, {
        name: data.name,
        resolutionNo: data.resolutionNo,
        description: data.description,
      });

      if (type) {
        navigate(`/scf-dcf-product/scf-product/edit/${params.id}/2`);
        await mutate();
        onTabChange("2");
        Message.success("Бүтээгдэхүүнийг амжилттай засварласан");
      } else {
        navigate("/scf-dcf-product/scf-product");
        Message.success("Бүтээгдэхүүнийг амжилттай засварлан хадгаллаа.");
      }
      await mutate();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const onFinanceSubmit = async (values: ISupplierLedFinanceData, type: boolean) => {
    setLoading(true);
    try {
      await ProductsApi.fundingSupplier(params.id as string, {
        recourseDays: values.recourseDays,
        suppTerminatePeriod: values.suppTerminatePeriod,
        productOverDueLimit: values.productOverDueLimit,
        overDueLimitType: values.overDueLimitType,
        overDueLimitValue: values.overDueLimitValue,
        minInvAmount: values.minInvAmount,
        minOfInv: values.minOfInv,
        maxOfInv: values.maxOfInv,
        minTenor: values.minTenor,
        maxTenor: values.maxTenor,
        minDays: values.minDays,
        maxDays: values.maxDays,
        borrowerType: values.borrowerType,

        buyerTerm: parseInt(values.buyerTerm),
      });

      if (type) {
        navigate(`/scf-dcf-product/scf-product/edit/${params.id}/3`);
        await mutate();
        onTabChange("3");
        Message.success("Амжилттай санхүүжилтын нөхцөлийг тохируулсан!");
      } else {
        navigate("/scf-dcf-product/scf-product");
        Message.success("Амжилттай санхүүжилтын нөхцөлийг хадгаллаа!");
      }
    } catch (error) {
      Message.error((error as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const onFeeSubmit = async (values: any, type: boolean) => {
    setLoading(true);
    try {
      if (type) {
        setAction(["CONTROL", values]);
      } else {
        await ProductsApi.commission(params.id as string, {
          suppFee: values.suppFee,
          suppAppFee: values.suppAppFee,
          suppCalculateDay: values.suppCalculateDay,
          suppMinFee: values.suppMinFee,
          suppDisbursementFee: values.suppDisbursementFee,
          suppFeeRule: values.suppFeeRule,
          suppPenaltyPercent: values.suppPenaltyPercent,
          suppPenaltyType: values.suppPenaltyType,

          buyerFee: values.buyerFee,
          buyerCalculateDay: values.buyerCalculateDay,
          buyerAppFee: values.buyerAppFee,
          buyerDisbursementFee: values.buyerDisbursementFee,
          buyerFeeRule: values.buyerFeeRule,
          buyerPenaltyType: values.buyerPenaltyType,
          buyerPenaltyPercent: values.buyerPenaltyPercent,
          buyerMinFee: values.buyerMinFee,
          toReview: false,
        });

        navigate("/scf-dcf-product/scf-product");
        Message.success("Амжилттай хадгаллаа!");
      }

      setLoading(false);
      await mutate();
    } catch (err) {
      setLoading(false);
      Message.error((err as HttpHandler)?.message!);
    }
  };

  const onTabChange = (_index: string) => {
    navigate(`/scf-dcf-product/scf-product/edit/${params.id}/${_index}`);
    setTabIndex(_index);
  };

  const reload = () => {
    ref.current?.reload();
  };

  const onCancel = async () => {
    setAction([]);
  };

  return (
    <>
      <PageLayout title="Supplier LED-SCF бүтээгдэхүүн" subTitle="Supplier LED-SCF бүтээгдэхүүнийг засварлах" breadcrumb={breadcrumbs} bgColor="#F8F9FA">
        <Paper radius="sm" p="md" withBorder>
          <Tabs defaultValue="1" value={tabIndex} onTabChange={onTabChange}>
            <Tabs.List>
              <Tabs.Tab value="1" icon={<IconFileText color={tabIndex === "1" ? "#3e166e" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Үндсэн мэдээлэл
                </Text>
              </Tabs.Tab>
              {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_MANAGEMENT_SL" && c.isEdit).length > 0 && (
                <Tabs.Tab value="2" icon={<IconMoneybag color={tabIndex === "2" ? "#9F20F7" : "#44566C"} />}>
                  <Text size="sm" weight={500} c="#44566C">
                    Санхүүжилт нөхцөл
                  </Text>
                </Tabs.Tab>
              )}

              {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_MANAGEMENT_SL" && c.isEdit).length > 0 && (
                <Tabs.Tab value="3" icon={<IconCashBanknote color={tabIndex === "3" ? "#3e166e" : "#44566C"} />}>
                  <Text size="sm" weight={500} c="#44566C">
                    Шимтгэл хураамж
                  </Text>
                </Tabs.Tab>
              )}
            </Tabs.List>

            <Tabs.Panel value="1">
              {tabIndex === "1" && data && (
                <>
                  <LoadingOverlay visible={loading} />
                  <ScfProductMainForm onSubmit={onMainSubmit} action={["update", data]} />
                </>
              )}
            </Tabs.Panel>
            {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_MANAGEMENT_SL" && c.isEdit).length > 0 && (
              <Tabs.Panel value="2">
                {tabIndex === "2" && data && (
                  <>
                    <LoadingOverlay visible={loading} />
                    <ScfProductFinanceForm onSubmit={onFinanceSubmit} action={["update", data]} />
                  </>
                )}
              </Tabs.Panel>
            )}
            {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_MANAGEMENT_SL" && c.isEdit).length > 0 && (
              <Tabs.Panel value="3">
                {tabIndex === "3" && data && (
                  <>
                    <LoadingOverlay visible={loading} />
                    <ScfProductFeeForm onSubmit={onFeeSubmit} action={["update", data]} />
                  </>
                )}
              </Tabs.Panel>
            )}
          </Tabs>
        </Paper>
      </PageLayout>
      <Modal opened={action[0] === "CONTROL"} onClose={() => setAction && setAction([])} withCloseButton={false}>
        <RequestPinForm
          title="Баталгаажуулах пин код"
          subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
          action={action}
          reload={reload}
          onCancel={onCancel}
          type="SUPPLIER_LED"
        />
      </Modal>
    </>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Санхүүжилтийн бүтээгдэхүүн",
  },
  {
    to: "/scf-dcf-product/scf-product",
    label: "Supplier LED бүтээгдэхүүний жагсаалт",
  },
  {
    label: "Засварлах",
  },
];
