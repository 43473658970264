import React from "react";
import { Paper, Space, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { ScfFundingRequestList } from "../../../components/scf-funding-request/list";
import { ScfSupplierList } from "../../../components/scf-supplier-led/list";
import { useNavigate, useParams } from "react-router";
import { IconShoppingCart, IconTruckLoading } from "@tabler/icons";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export const ScfFinanceRequest = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [tabKey, setTabKey] = React.useState<string | null>(params.type || "FINANCE");
  const titles = (type: string) => {
    switch (type) {
      case "FINANCE": {
        return "Buyer LED хүсэлт";
      }

      case "SUPPLIER": {
        return "Supplier LED хүсэлт";
      }
    }
  };

  const title = (type: string) => {
    switch (type) {
      case "FINANCE": {
        return "Buyer LED хүсэлтийн жагсаалт";
      }
      case "SUPPLIER": {
        return "Supplier LED хүсэлтийн жагсаалт";
      }
    }
  };

  const onChange = (value: string) => {
    navigate(`/scf/request/${value}`);
    setTabKey(value);
  };

  const breadcrumbs = useBreadcrumb(titles(tabKey as string));
  return (
    <PageLayout title={title(tabKey as string)} subTitle="Ирсэн эрт санхүүжилтийн хүсэлтийн жагсаалт" breadcrumb={breadcrumbs} extra={[]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs value={tabKey} onTabChange={(e) => onChange(e as string)}>
          <Tabs.List>
            {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_BL" && c.isView).length > 0 && (
              <Tabs.Tab value="FINANCE" icon={<IconShoppingCart color={tabKey === "FINANCE" ? "#3e166e" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Buyer LED хүсэлт
                </Text>
              </Tabs.Tab>
            )}

            {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_SL" && c.isView).length > 0 && (
              <Tabs.Tab value="SUPPLIER" icon={<IconTruckLoading color={tabKey === "SUPPLIER" ? "#3e166e" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Supplier LED хүсэлт
                </Text>
              </Tabs.Tab>
            )}
          </Tabs.List>
          <Space h={10} />
          {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_BL" && c.isView).length > 0 && (
            <Tabs.Panel value="FINANCE">
              <ScfFundingRequestList />
            </Tabs.Panel>
          )}

          {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_SL" && c.isView).length > 0 && (
            <Tabs.Panel value="SUPPLIER">{<ScfSupplierList />}</Tabs.Panel>
          )}
        </Tabs>
      </Paper>
    </PageLayout>
  );
};

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "SCF санхүүжилт",
  },
  {
    label: type,
  },
];
