import React from "react";
import { ActionIcon, Modal, Paper, Tabs, Text } from "@mantine/core";
import { IconArticle, IconClipboardText, IconCreditCard, IconFileInvoice, IconX } from "@tabler/icons";
import { PageLayout } from "../layout";
import { ScfSupplierFinanceRequestInvoiceForm } from "./invoice-form";
import { ScfSupplierFinanceRequestForm } from "./request-form";
import { ScfSupplierFinanceLimitList } from "./limit-list";
import { ScfSupplierFinanceDecisionForm } from "./decision-form";
import { ScfSupplierFinanceDecisionDetail } from "./decision-detail";
import { ScfRequestPinForm } from "./pin";

export function ScfSupplierFinanceRequestTabs({
  action,
  onCancel,
  setAction,
  reload,
}: {
  action: any;
  setAction: (e: any) => void;
  onCancel?: () => void;
  reload?: () => void;
}) {
  const [tabIndex, setTabIndex] = React.useState<string | null>("1");
  const [pinCheck, setPinCheck] = React.useState<string[]>([]);

  const onTabChange = (_index: any) => {
    setTabIndex(_index);
  };

  const onSubmit = (values: any) => {
    setPinCheck(["PIN", values]);
  };

  return (
    <>
      <PageLayout
        title={`Санхүүжих хүсэлт ${action[1]?.refCode}`}
        extra={[
          <ActionIcon onClick={() => onCancel && onCancel()} key={1}>
            <IconX />
          </ActionIcon>,
        ]}>
        <Paper radius="sm" p="md" withBorder>
          <Tabs defaultValue="1" value={tabIndex} onTabChange={onTabChange}>
            <Tabs.List>
              <Tabs.Tab icon={<IconFileInvoice color={tabIndex === "1" ? "#3e166e" : "#44566C"} />} value="1">
                <Text size="sm" weight={500} c="#44566C">
                  Нэхэмжлэх
                </Text>
              </Tabs.Tab>
              <Tabs.Tab icon={<IconCreditCard color={tabIndex === "2" ? "#3e166e" : "#44566C"} />} value="2">
                <Text size="sm" weight={500} c="#44566C">
                  Хүсэлт
                </Text>
              </Tabs.Tab>
              <Tabs.Tab icon={<IconClipboardText color={tabIndex === "3" ? "#3e166e" : "#44566C"} />} value="3">
                <Text size="sm" weight={500} c="#44566C">
                  Лимит ашиглалт
                </Text>
              </Tabs.Tab>
              <Tabs.Tab icon={<IconArticle color={tabIndex === "4" ? "#3e166e" : "#44566C"} />} value="4">
                <Text size="sm" weight={500} c="#44566C">
                  Шийдвэр
                </Text>
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="1">
              {tabIndex === "1" && <ScfSupplierFinanceRequestInvoiceForm action={action} onTabChange={onTabChange} onCancel={onCancel} />}
            </Tabs.Panel>
            <Tabs.Panel value="2">{tabIndex === "2" && <ScfSupplierFinanceRequestForm action={action} onTabChange={onTabChange} />}</Tabs.Panel>
            {<Tabs.Panel value="3">{tabIndex === "3" && <ScfSupplierFinanceLimitList action={action} onTabChange={onTabChange} />}</Tabs.Panel>}
            <Tabs.Panel value="4">
              {tabIndex === "4" && (
                <>
                  {action[0] === "request" ? (
                    <ScfSupplierFinanceDecisionForm action={action} onTabChange={onTabChange} onSubmit={onSubmit} />
                  ) : (
                    <ScfSupplierFinanceDecisionDetail action={action} onTabChange={onTabChange} setAction={setAction} />
                  )}
                </>
              )}
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </PageLayout>
      <Modal opened={pinCheck[0] === "PIN"} onClose={() => setPinCheck([])} withCloseButton={false}>
        <ScfRequestPinForm
          title="Баталгаажуулах пин код"
          subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
          action={action}
          reload={reload}
          pinCheck={pinCheck}
          setPinCheck={setPinCheck}
          onCancel={onCancel}
        />
      </Modal>
    </>
  );
}
