import useSwr from "swr";
import { useNavigate, useParams } from "react-router";
import { ProgramApi } from "../../../apis";
import React from "react";
import { PageLayout } from "../../../components/layout";
import { Button, Group, LoadingOverlay, Paper, Tabs, Text } from "@mantine/core";
import { BuyerLedMainForm, IBuyerProgramFormData } from "../../../components/buyer-led-program/main-form";
import { BuyerLedCustomerForm } from "../../../components/buyer-led-program/customer-form";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";
import { IconFileText, IconTruckLoading } from "@tabler/icons";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function BuyerLedProgramEdit() {
  const params = useParams();
  const breadcrumbs = useBreadcrumb();
  const { id, step } = params;
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = React.useState(step || "1");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [type, setType] = React.useState<string>("");
  const [result, setResult] = React.useState<any>();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { data, mutate, isLoading } = useSwr(`/api/program/buyer-led/${params.id}`, async () => await ProgramApi.get(id as string));

  const onTabChange = (_index: string) => {
    navigate(`/scf-dcf/dcf-program/${params.code}/edit/${params.id}/${_index}`);
    setTabIndex(_index);
  };

  const onMainSubmit = async (value: IBuyerProgramFormData, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    setLoading(true);
    try {
      await ProgramApi.update(params.id as string, {
        name: value.name,
        resolutionNo: value.resolutionNo,
        fundType: value.fundType,
        description: value.description,
        programLimit: value?.programLimit,
        disburseAccId: value.disburseAccId,
        feeAccId: value.feeAccId,
        repayAccId: value.repayAccId,
        businessId: value.businessId,
        programSector1Ids: value?.programSector1Ids,
        programSector2Ids: (value?.programSector2Ids || []).map((c: any) => c?.id),
        programSector3Ids: (value?.programSector3Ids || []).map((c: any) => c?.id),
        rechargeable: !data.rechargeable ? false : data.rechargeable === "FALSE" ? false : true,
      });

      if (type === "NEXT") {
        onTabChange("2");
      } else {
        navigate(`/scf-dcf/dcf-program`);
      }
      mutate();
      Message.success("Хөтөлбөрийн амжилттай засварлан хадгалсан!");
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const toReview = async () => {
    if ((user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isEdit).length > 0) {
      setLoading(true);
      try {
        await ProgramApi.toReview(params.id as string);
        navigate("/scf-dcf/dcf-program");
        Message.success("Хяналтад амжилттай илгээлээ.");
        setLoading(false);
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
      }
      setLoading(false);
    }
    return [];
  };

  return (
    <PageLayout
      title="Buyer LED хөтөлбөр засах"
      subTitle={`Buyer LED хөтөлбөр бүртгэлийг засварлах`}
      breadcrumb={breadcrumbs}
      bgColor="#F8F9FA"
      extra={[
        <>
          {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isEdit).length > 0 && (
            <Group key={1}>
              {result?.length !== 0 && (
                <Button
                  key="button-key"
                  disabled={
                    !data?.hasAnchor || data?.participants?.length === 0 || data?.registrationStatus === "REGISTERED" || data?.registrationStatus === "REVIEWED"
                  }
                  onClick={() => toReview()}>
                  Хяналтад илгээх
                </Button>
              )}
            </Group>
          )}
        </>,
      ]}>
      <Paper radius="sm" p="md" withBorder>
        <LoadingOverlay visible={isLoading || loading} />
        {data && (
          <Tabs defaultValue="1" value={tabIndex} onTabChange={onTabChange}>
            <Tabs.List>
              <Tabs.Tab value="1" icon={<IconFileText color={tabIndex === "1" ? "#3e166e" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Хөтөлбөрийн мэдээлэл
                </Text>
              </Tabs.Tab>
              {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_BL" && c.isView).length > 0 && (
                <Tabs.Tab value="2" icon={<IconTruckLoading color={tabIndex === "2" ? "#3e166e" : "#44566C"} />}>
                  <Text size="sm" weight={500} c="#44566C">
                    Нийлүүлэгч бизнесүүд
                  </Text>
                </Tabs.Tab>
              )}
            </Tabs.List>
            <Tabs.Panel value="1">
              {tabIndex === "1" && <BuyerLedMainForm onSubmit={onMainSubmit} action={["update", data || {}]} setType={setType} />}
            </Tabs.Panel>
            {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_BL" && c.isView).length > 0 && (
              <Tabs.Panel value="2">
                {tabIndex === "2" && (
                  <BuyerLedCustomerForm
                    data={data}
                    rows={result}
                    setResult={setResult}
                    onSuccess={async () => {
                      setLoading(true);
                      await mutate();
                      setLoading(false);
                    }}
                  />
                )}
              </Tabs.Panel>
            )}
          </Tabs>
        )}
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Санхүүжилтийн хөтөлбөр",
  },
  {
    to: "/scf-dcf/dcf-program",
    label: "Buyer LED хөтөлбөр",
  },
  {
    label: "Засварлах",
  },
];
