import React from "react";
import { ActionIcon, LoadingOverlay, Paper, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { IconArticle, IconClipboardText, IconCreditCard, IconFileInvoice, IconX } from "@tabler/icons";
import useSwr from "swr";
import { RequestApi } from "../../../apis";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";
import { ScfFundingInvoiceForm } from "../../../components/scf-funding-provided/invoice-form";
import { ScfFundingRequestForm } from "../../../components/scf-funding-provided/request-form";
import { ScfFundingProvidedLimitForm } from "../../../components/scf-funding-provided/limit-form";
import { ScfFundingDecisionForm } from "../../../components/scf-funding-provided/decision-detail";

type Props = {
  action: any;
  setAction: (e: any) => void;
  onCancel: () => void;
};

export const ScfFundingProvidedDetail = ({ action, setAction, onCancel }: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState<string | null>("1");

  const onTabChange = (_index: any) => {
    setTabIndex(_index);
  };

  const { data: requestData } = useSwr(`/api/scf/request/${action[1]}`, async () => {
    setLoading(true);
    try {
      if (!action[1]) {
        return [];
      }
      if (action[0] === "pin") {
        return [];
      }
      let res = await RequestApi.get(`${action[1]}`);
      setLoading(false);
      return res;
    } catch (error) {
      Message.error((error as HttpHandler)?.message!);
      setLoading(false);
    }
  });

  return (
    <>
      <PageLayout
        title={`Санхүүжих хүсэлт ${requestData?.refCode}`}
        extra={[
          <ActionIcon onClick={() => onCancel()} key={1}>
            <IconX />
          </ActionIcon>,
        ]}>
        <Paper radius="sm" p="md" withBorder>
          <LoadingOverlay visible={loading} />
          <Tabs defaultValue="1" value={tabIndex} onTabChange={onTabChange}>
            <Tabs.List>
              <Tabs.Tab icon={<IconFileInvoice color={tabIndex === "1" ? "#3e166e" : "#44566C"} />} value="1">
                <Text size="sm" weight={500} c="#44566C">
                  Нэхэмжлэх
                </Text>
              </Tabs.Tab>
              <Tabs.Tab icon={<IconCreditCard color={tabIndex === "2" ? "#3e166e" : "#44566C"} />} value="2">
                <Text size="sm" weight={500} c="#44566C">
                  Хүсэлт
                </Text>
              </Tabs.Tab>
              <Tabs.Tab icon={<IconClipboardText color={tabIndex === "3" ? "#3e166e" : "#44566C"} />} value="3">
                <Text size="sm" weight={500} c="#44566C">
                  Лимит ашиглалт
                </Text>
              </Tabs.Tab>
              <Tabs.Tab icon={<IconArticle color={tabIndex === "4" ? "#3e166e" : "#44566C"} />} value="4">
                <Text size="sm" weight={500} c="#44566C">
                  Шийдвэр
                </Text>
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="1">
              {tabIndex === "1" && <ScfFundingInvoiceForm onCancel={onCancel} action={requestData} setTabIndex={setTabIndex} />}
            </Tabs.Panel>
            <Tabs.Panel value="2">
              {tabIndex === "2" && <ScfFundingRequestForm action={requestData} onCancel={onCancel} setTabIndex={setTabIndex} />}
            </Tabs.Panel>
            <Tabs.Panel value="3">
              {tabIndex === "3" && <ScfFundingProvidedLimitForm onCancel={onCancel} action={requestData} setTabIndex={setTabIndex} setAction={setAction} />}
            </Tabs.Panel>
            <Tabs.Panel value="4">
              {tabIndex === "4" && <ScfFundingDecisionForm action={requestData} onCancel={onCancel} setTabIndex={setTabIndex} setAction={setAction} />}
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </PageLayout>
    </>
  );
};
