import React from "react";
import { Button, LoadingOverlay, Paper, Space, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { ScfFinanceList } from "../../../components/scf-finance/list";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";
import { ProductRequestApi } from "../../../apis";
import { useNavigate, useParams } from "react-router";
import { IconBoxSeam, IconDownload } from "@tabler/icons";

export function ScfDcfPProductFinance() {
  const breadcrumbs = useBreadcrumb();
  const params = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const [tabKey, setTabKey] = React.useState<string | null>(params.type || "SCF");

  const onExport = async () => {
    setLoading(true);

    try {
      let res = await ProductRequestApi.list({
        filter: { productType: "", anchorPartnerId: "", anchorBusinessId: "", productId: "", programId: "", startDate: "", endDate: "", excel: `${true}` },
        offset: { page: 1, limit: 10 },
      });
      window.open(res?.excelUrl, "_blank");
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  const onChange = (value: string) => {
    navigate(`/scf-dcf-product/finance/${value}`);
    setTabKey(value);
  };

  return (
    <PageLayout
      title={tabKey === "SCF" ? "SCF бүтээгдэхүүний санхүүжилт" : "DCF бүтээгдэхүүний санхүүжилт"}
      subTitle={tabKey === "SCF" ? "SCF бүтээгдэхүүнээр олгосон санхүүжилтийн мэдээлэл" : "DCF бүтээгдэхүүнээр олгосон санхүүжилтийн мэдээлэл"}
      breadcrumb={breadcrumbs}
      extra={[
        <Button size="xs" key={0} leftIcon={<IconDownload />} variant="outline" onClick={() => onExport()}>
          EXCEL
        </Button>,
      ]}
      bgColor="#F8F9FA">
      <Paper radius="sm" p="md" withBorder>
        <Tabs value={tabKey} onTabChange={(e) => onChange(e as string)}>
          <Tabs.List p={0}>
            <Tabs.Tab value="SCF" icon={<IconBoxSeam color={tabKey === "SCF" ? "#3e166e" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                SCF санхүүжилт
              </Text>
            </Tabs.Tab>
            {/* <Tabs.Tab value="DCF" icon={<IconTruckDelivery color={tabKey === "DCF" ? "#3e166e" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                DCF санхүүжилт
              </Text>
            </Tabs.Tab> */}
          </Tabs.List>
          <Space h={10} />
          <Tabs.Panel value="SCF">
            <ScfFinanceList />
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Санхүүжилтийн бүтээгдэхүүн" },
  {
    label: "SCF санхүүжилт",
  },
];
