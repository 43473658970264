import React from "react";
import { ActionIcon, createStyles, Paper, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { ScfRepaymentInformationForm } from "../../../components/scf-repayment/information-form";
import { IconList, IconNotes, IconX } from "@tabler/icons";
import { ScfFinanceRepaymnetHistoryList } from "../../../components/scf-repayment/history";

type Props = {
  action: any;
  onCancel: () => void;
};

export const ScfRepaymentDetail = ({ action, onCancel }: Props) => {
  const { theme } = useStyle();
  const [tabIndex, setTabIndex] = React.useState("1");

  const onTabChange = (_index: any) => {
    setTabIndex(_index);
  };

  return (
    <>
      <PageLayout
        title={`Эргэн төлөлтийн нэхэмжлэх ${action[1]?.invRefCode}`}
        bgColor={theme.colors.gray[0]}
        extra={[
          <ActionIcon onClick={() => onCancel()} key={1}>
            <IconX />
          </ActionIcon>,
        ]}>
        <Paper radius="sm" p="md" withBorder>
          <Tabs defaultValue="1" value={tabIndex} onTabChange={onTabChange}>
            <Tabs.List>
              <Tabs.Tab icon={<IconNotes color={tabIndex === "1" ? "#3e166e" : "#44566C"} />} value="1">
                <Text size="sm" weight={500} c="#44566C">
                  Үндсэн мэдээлэл
                </Text>
              </Tabs.Tab>
              <Tabs.Tab icon={<IconList color={tabIndex === "2" ? "#3e166e" : "#44566C"} />} value="2">
                <Text size="sm" weight={500} c="#44566C">
                  Төлөлтийн түүх
                </Text>
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="1">{tabIndex === "1" && <ScfRepaymentInformationForm action={action} onCancel={onCancel} />}</Tabs.Panel>
            <Tabs.Panel value="2">{tabIndex === "2" && <ScfFinanceRepaymnetHistoryList action={action[1]} onCancel={onCancel} />}</Tabs.Panel>
          </Tabs>
        </Paper>
      </PageLayout>
    </>
  );
};

const useStyle = createStyles(() => ({}));
