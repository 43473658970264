import { Anchor, Badge, ColorSwatch, Divider, Flex, Grid, Group, Paper, Space, Table, Text, createStyles } from "@mantine/core";
import { IProductDashboard } from "../../../interfaces/IProductDashbord";
import { IconArrowDown, IconArrowUp, IconBuilding, IconBuildingCommunity, IconBuildingSkyscraper, IconCalendarEvent, IconCash } from "@tabler/icons";
import { DatePicker } from "@mantine/dates";
import { IPByPie, IPNumberSurvey, IPRecentActivated, IPRecentTrx } from "../../../models/ProductDashboard";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { dateFormat } from "../../../utils/date";
import { IReference } from "../../../models/General";
import { useSelector } from "react-redux";
import { IGeneral } from "../../../interfaces/IGeneral";
import { tugrug } from "../../../utils";
import dayjs from "dayjs";

export function ScfDcfProductDashboardReal({ data, pieDate, setPieDate }: { data: IProductDashboard; pieDate: any; setPieDate: any }) {
  const { classes, theme } = useStyles();
  const nowMoment = new Date();
  const { accountTypes } = useSelector((state: { general: IGeneral }) => state.general);

  const getPieOptions = () => ({
    title: {
      text: "",
      align: "center",
    },
    subtitle: {
      useHTML: true,
      text: "",
      floating: true,
      verticalAlign: "middle",
      y: 30,
    },
    legend: {
      enabled: false,
    },

    tooltip: {
      valueDecimals: 0,
      valueSuffix: " ",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        colorByPoint: true,
        type: "pie",
        size: "100%",
        innerSize: "80%",
        dataLabels: {
          enabled: false,
          crop: false,
          distance: "-10%",
          style: {
            fontWeight: "bold",
            fontSize: "16px",
          },
          connectorWidth: 0,
        },
      },
    },
    series: [
      {
        type: "pie",
        name: "",
        data: (data?.byPie || []).map((i) => ({
          name: i?.name,
          y: i?.count,
          color: i?.color,
        })),
      },
    ],
  });

  return (
    <Grid>
      {(data?.numberSurvey || []).map((item: IPNumberSurvey, index: number) => {
        const DiffIcon = item.changedCount < 0 ? IconArrowDown : IconArrowUp;

        return (
          <Grid.Col sm={12} md={6} lg={4} xl={3} key={index}>
            <Paper withBorder radius="md">
              <Group spacing={8} p="sm" noWrap pb="xs">
                {index === 0 && <IconCash color={theme.colors.gray[6]} />}
                {index === 1 && <IconBuilding color={theme.colors.gray[6]} />}
                {index === 2 && <IconBuildingCommunity color={theme.colors.gray[6]} />}
                {index === 3 && <IconBuildingSkyscraper color={theme.colors.gray[6]} />}
                <Text fz="sm" fw={600} color={theme.colors.gray[6]}>
                  {item.name || "-"}
                </Text>
              </Group>
              <Group>
                <div className={classes.grdnt}></div>
                <Space />
                <Text fz="xl" fw={500}>
                  {item.count || 0}
                </Text>
              </Group>
              <Group position="right" p={"sm"}>
                <Text size={"xs"} color={theme.colors.gray[6]}>
                  Өмнөх өдрөөс
                </Text>
                <Badge color={item.changedCount < 0 ? "red" : "green"} className={item.changedCount < 0 ? classes.red : classes.green}>
                  <Flex align={"center"} gap={8}>
                    <DiffIcon size={"0.8rem"} stroke={3} />
                    <Text>
                      {item.changedCount < 0 && "-"}
                      {Math.abs(item.changedCount)} %
                    </Text>
                  </Flex>
                </Badge>
              </Group>
            </Paper>
          </Grid.Col>
        );
      })}
      <Grid.Col md={12} lg={8} xl={9}>
        <Paper withBorder py="4px">
          <Group position="apart">
            <Group align="center">
              <div className={classes.grdnt}></div>
              <Text component="h1">Бүтээгдэхүүний статус</Text>
            </Group>
            <DatePicker
              key={1}
              value={pieDate ? pieDate : nowMoment}
              maxDate={nowMoment || pieDate}
              minDate={dayjs(nowMoment || pieDate)
                .subtract(90, "days")
                .toDate()}
              rightSection={<IconCalendarEvent color="gray" />}
              placeholder="Өдөр сонгох"
              onChange={setPieDate}
              mr={15}
            />
          </Group>
          <Divider mr={15} ml={15} />
          <Flex justify="space-around" align="center">
            <Group w="100%">
              <HighchartsReact highcharts={Highcharts} options={getPieOptions()} />
            </Group>
            <Flex direction="column" gap="sm" w="100%" mr={15}>
              {(data?.byPie || []).map((c: IPByPie, index: number) => {
                return (
                  <Group position="apart" key={index} align="start" ml={40}>
                    <Group ml={5}>
                      <ColorSwatch color={c.color} size={6} />
                      <Text fw={500} color="#14182d">
                        {c?.name || "-"}
                      </Text>
                    </Group>
                    <Flex mr={5}>
                      <Text fw={500} color="#557d94">
                        {c?.count || 0}
                      </Text>
                    </Flex>
                  </Group>
                );
              })}
            </Flex>
          </Flex>
        </Paper>
      </Grid.Col>
      <Grid.Col md={12} lg={4} xl={3}>
        <Paper p="sm" withBorder>
          <Text fz="lg" fw={600}>
            Сүүлд идэвхжсэн
          </Text>
          <Divider my="xs" />
          <Flex direction="column" gap="sm">
            {(data?.recentActivated || []).map((c: IPRecentActivated, index: number) => {
              return (
                <Group position="apart" key={index} align="start" style={{ background: "#F8F9FA" }}>
                  <Group ml={5}>
                    <div>
                      <Text fw={500} color="#14182d">
                        {c?.name || "-"}
                      </Text>
                      <Text c="#557d94" fw={500} size="sm">
                        {c?.refCode || "-"}
                      </Text>
                    </div>
                  </Group>
                  <Text fw={500} color="#557d94" mr={5}>
                    {dateFormat(c?.activatedDate)}
                  </Text>
                </Group>
              );
            })}
          </Flex>
        </Paper>
      </Grid.Col>
      <Grid.Col md={12} lg={8} xl={9}>
        <Paper withBorder py="4px">
          <Group position="apart">
            <Group align="center">
              <div className={classes.grdnt}></div>
              <Text component="h1">Сүүлд орсон бүтээгдэхүүний орлого</Text>
            </Group>
            <Anchor size="sm" px="xs" fw={500} onClick={() => {}} c="#3e166e">
              Бүгдийг харах
            </Anchor>
          </Group>
          <Paper px="lg" style={{ overflow: "auto" }}>
            <Table miw="max-content">
              <thead>
                <tr>
                  <th>Огноо, цаг</th>
                  <th>Дансны дугаар</th>
                  <th>Банкны нэр</th>
                  <th>Орлогын дүн</th>
                  <th>Гүйлгээний төрөл</th>
                  <th>Шилжүүлсэн нэр</th>
                </tr>
              </thead>
              <tbody>
                {(data?.recentTrx || []).map((item: IPRecentTrx, index: number) => (
                  <tr key={index}>
                    <td>
                      <Text size="sm" weight={500} c="#44566C">
                        {dateFormat(item.date)}
                      </Text>
                    </td>
                    <td>
                      <Text size="sm" weight={500} c="#44566C">
                        {item.number}
                      </Text>
                    </td>
                    <td>
                      <Text size="sm" weight={500} c="#44566C">
                        {item.bankName}
                      </Text>
                    </td>
                    <td>
                      <Text size="sm" weight={500} c="#44566C">
                        {tugrug(item.amount)}
                      </Text>
                    </td>
                    <td>
                      <Badge size="md" radius="sm" variant="outline">
                        {(accountTypes || []).find((c: IReference) => c.code === item.type)?.name || "-"}
                      </Badge>
                    </td>
                    <td>
                      <Text size="sm" weight={500} c="#44566C">
                        {item.payerAccountNumber}
                      </Text>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Paper>
        </Paper>
      </Grid.Col>
    </Grid>
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl * 1.5,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  grdnt: {
    background: "#3e166e",
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
  green: {
    borderColor: theme.colors.green[6],
  },
  red: {
    borderColor: theme.colors.red[6],
  },
}));
