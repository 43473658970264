import React from "react";
import useSwr from "swr";
import { useNavigate, useParams } from "react-router";
import { PageLayout } from "../../../components/layout";
import { LoadingOverlay, Paper, Tabs, Text } from "@mantine/core";
import { ProgramApi } from "../../../apis";
import { SupplierProgramInformationForm } from "../../../components/supplier-led-program/reviewed-information";
import { SupplierProgramBuyerList } from "../../../components/supplier-led-program/buyer-list";
import { IconFileText, IconShoppingCart } from "@tabler/icons";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function SupplierLedProgramReview() {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const params = useParams();
  const { step } = params;
  const [tabKey, setTabKey] = React.useState<string>((step as string) || "1");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { data, isLoading } = useSwr(`/api/program/supplier-led/${params.id}/reviewed`, async () => await ProgramApi.get(params.id!), {
    fallbackData: null,
  });

  const onTabChange = (_index: string) => {
    navigate(`/scf-dcf/scf-program/${params.id}/review/${_index}`);
    setTabKey(_index);
  };

  return (
    <>
      <PageLayout title="Supplier LED хөтөлбөр" subTitle="Supplier LED хөтөлбөрийг хянах" breadcrumb={breadcrumbs} bgColor="#F8F9FA">
        <Paper radius="sm" p="md" withBorder>
          <Tabs
            onTabChange={(e: string) => {
              onTabChange(e);
            }}
            value={tabKey}
            defaultValue={tabKey}>
            <Tabs.List>
              <Tabs.Tab value="1" icon={<IconFileText color={tabKey === "1" ? "#3e166e" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Хөтөлбөрийн мэдээлэл
                </Text>
              </Tabs.Tab>

              {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_SL" && c.isView).length > 0 && (
                <Tabs.Tab value="2" icon={<IconShoppingCart color={tabKey === "2" ? "#3e166e" : "#44566C"} />}>
                  <Text size="sm" weight={500} c="#44566C">
                    Худалдан авагч бизнесүүд
                  </Text>
                </Tabs.Tab>
              )}
              {/* <Tabs.Tab value="3" icon={<IconReceiptTax color={tabKey === "3" ? "#3e166e" : "#44566C"} />} disabled>
                <Text size="sm" weight={500} c="#44566C">
                  Төлөлтийн хувиар
                </Text>
              </Tabs.Tab> */}
            </Tabs.List>
            {isLoading ? (
              <LoadingOverlay visible />
            ) : (
              <>
                <Tabs.Panel value="1">
                  {tabKey === "1" && (
                    <SupplierProgramInformationForm
                      action={["detail", data]}
                      basicSector={(data?.programSectors || []).filter((c: any) => c.level === 1)}
                      sector={(data?.programSectors || []).filter((c: any) => c.level === 2)}
                      subSector={(data?.programSectors || []).filter((c: any) => c.level === 3)}
                    />
                  )}
                </Tabs.Panel>

                {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_SL" && c.isView).length > 0 && (
                  <Tabs.Panel value="2">{tabKey === "2" && <SupplierProgramBuyerList />}</Tabs.Panel>
                )}
              </>
            )}
          </Tabs>
        </Paper>
      </PageLayout>
    </>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Санхүүжилтийн хөтөлбөр",
  },
  {
    to: "/scf-dcf/scf-program",
    label: "Supplier LED хөтөлбөр",
  },
  {
    label: "Хөтөлбөр хянах",
  },
];
