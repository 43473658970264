import React from "react";
import * as yup from "yup";
import { FormLayout } from "../layout";
import { Form } from "../form";
import { CloseButton, Grid, Table as TableCore, Input, Paper, ScrollArea, Space, Text, Flex, Avatar, Divider, Group, Button } from "@mantine/core";
import { tugrug } from "../../utils";
import { IconPhoto } from "@tabler/icons";
import noimage from "../../assets/no-image.png";
import { NumberCurrencyField } from "../form/number-currency-field";
import { ProgramApi } from "../../apis";
import { useParams } from "react-router";
import { dateTimeFormat } from "../../utils/date";
import { Message } from "../../utils/message";
import HttpHandler from "../../utils/http-handler";

const schema = yup.object({
  availableAmount: yup
    .number()
    .max(9999999999999, "13-с бага оронтой дүн оруулна уу!")
    .min(0, "0-с их дүн оруулна уу!")
    .typeError("Зөвхөн тоо оруулна уу!")
    .required("Заавал бөглөнө!")
    .nullable(),
});

export function BuyerLedProgramReviewCustomerEditForm({ action, reload, onCancel }: { action: any; reload: () => void; onCancel: () => void }) {
  const params = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data] = React.useState<any>({
    availableAmount: "",
    ...(action && action[0] === "edit"
      ? {
          ...action[1],
          availableAmount: action[1]?.availableAmount,
        }
      : {}),
  });

  const onSubmit = async (value: any) => {
    setLoading(true);
    try {
      await ProgramApi.participantAdd(`${params?.id}`, {
        smes: [
          {
            smeBusinessId: data?.smeBusinessId,
            subLimit: value?.availableAmount,
          },
        ],
      });
      Message.success(`Таны хүсэлт амжилттай . ${dateTimeFormat(new Date())}`);
      reload();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <FormLayout
      title="Анкорын хамтрагч Supplier бизнесүүд"
      subTitle="Анкорын хамтрагч Supplier бизнесүүд, нийлүүлэгчийн дэд лимитийг өөрчлөх"
      my={0}
      extra={[<CloseButton key="cancel" onClick={() => onCancel()} />]}>
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {({ values, setFieldValue }) => {
          return (
            <>
              <Paper radius="sm" p="lg" withBorder>
                <Grid>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Хөтөлбөрт бүртгэсэн нийлүүлэгч тоо">
                      <Text color="indigo">1</Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Дэд лимитийн нийт дүн">
                      <Text color="indigo">{tugrug(values?.availableAmount || 0)}</Text>
                    </Input.Wrapper>
                  </Grid.Col>
                </Grid>
              </Paper>
              <Space h={"xl"} />
              <Input.Wrapper
                label="Дэд лимит болон харьцаж ажиллах дансыг тохируулна уу."
                description={`Та ${values?.smes?.length || 0}  бизнес сонгосон байна. `}
                required>
                <ScrollArea style={{ width: "100%", zIndex: 0, minHeight: "400px" }}>
                  <TableCore captionSide="bottom">
                    <thead>
                      <tr>
                        <th>
                          <Flex w="50px">№</Flex>
                        </th>
                        <th>
                          <Flex w="100px">ТТД</Flex>
                        </th>
                        <th>
                          <Flex w="220px">Партнер нэр</Flex>
                        </th>
                        <th>
                          <Flex w="220px">Нийлүүлэгч Бизнес нэр</Flex>
                        </th>
                        <th>
                          <Flex w="220px">Нийлүүлэгчийн санхүү</Flex>
                        </th>
                        <th>
                          <Flex w="220px">Нийлүүлэгчийн дэд лимит</Flex>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <td>
                        <Text size="sm" weight={500} c="#44566C">
                          1.
                        </Text>
                      </td>
                      <td>
                        <Text size="sm" weight={500} c="#44566C">
                          {data?.regNumber || "-"}
                        </Text>
                      </td>
                      <td>
                        <Flex align="center" gap={8} w={160}>
                          <Text w={260}>
                            <Text size="sm" weight={500} c="#44566C">
                              {data?.partner?.businessName || "-"}
                            </Text>
                            <Text size="sm" weight={500} c="#3e166e">
                              {data?.partner?.refCode || "-"}
                            </Text>
                          </Text>
                        </Flex>
                      </td>
                      <td>
                        <Flex align="center" gap={8} w={200}>
                          <Text w={180}>
                            <Text size="sm" weight={500} c="#44566C">
                              {data?.profileName || "-"}
                            </Text>
                            <Text size="sm" weight={500} c="#3e166e">
                              # {data?.refCode || "-"}
                            </Text>
                          </Text>
                        </Flex>
                      </td>
                      <td>
                        <Flex align="center" gap={8} w={200}>
                          <Avatar src={data?.finUser?.avatar !== null ? data?.finUser?.avatar : noimage} size={42}>
                            <IconPhoto />
                          </Avatar>
                          <Text w={220}>
                            <Text size="sm" weight={500} c="#868E96">
                              {data?.finUser?.lastName || "-"}
                            </Text>
                            <Text size="sm" weight={500} c="#44566C">
                              {data?.finUser?.firstName || "-"}
                            </Text>
                          </Text>
                        </Flex>
                      </td>
                      <td>
                        <NumberCurrencyField precision={2} size="xs" name="availableAmount" placeholder="0" noError={true} max={999999999999} />
                      </td>
                    </tbody>
                  </TableCore>
                </ScrollArea>
              </Input.Wrapper>
              <Divider />
              <Group position="right" mt={"md"}>
                <Button type="submit" key={1} loading={loading}>
                  Хөтөлбөрт хамруулах
                </Button>
              </Group>
            </>
          );
        }}
      </Form>
    </FormLayout>
  );
}
