import React from "react";
import { useNavigate, useParams } from "react-router";
import { PageLayout } from "../../../components/layout";
import { Button, Paper, Tabs, Text } from "@mantine/core";
import { IconPackage, IconPlus, IconShoppingCart } from "@tabler/icons";
import { ProductSupplierLedList } from "../product-supplier/list";
import { ProductBuyerLedList } from "../product-buyer/list";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function ProductTab() {
  const params = useParams();
  const navigate = useNavigate();
  const [tabKey, setTabKey] = React.useState<string | null>(params.type || "BUYER");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const titles = (val: string) => {
    switch (val) {
      case "SUPPLIER": {
        return "Supplier-LED бүтээгдэхүүн";
      }

      case "BUYER": {
        return "Buyer-LED бүтээгдэхүүн";
      }
    }
  };

  const onChange = (value: string) => {
    navigate(`/cf/product/${value}`);
    setTabKey(value);
  };

  const breadcrumbs = useBreadcrumb(titles(tabKey as string));

  return (
    <PageLayout
      title={tabKey === "BUYER" ? "Buyer-LED бүтээгдэхүүн жагсаалт" : "Supplier-LED бүтээгдэхүүн жагсаалт"}
      subTitle={
        tabKey === "BUYER"
          ? "Уламжлалт зээлийн лимит суурьтайгаа олгох санхүүжилтийн бүтээгдэхүүн"
          : "Уламжлалт зээлийн лимит суурьтайгаа олгох санхүүжилтийн бүтээгдэхүүн"
      }
      breadcrumb={breadcrumbs}
      extra={[
        <div key={1}>
          {tabKey === "BUYER" &&
            (user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PRODUCT_MANAGEMENT_BL" && c.isCreate).length > 0 && (
              <Button
                size="sm"
                key={0}
                onClick={() => {
                  tabKey === "BUYER" ? navigate("/cf/product/BUYER/new") : navigate("/cf/product/SUPPLIER/new");
                }}
                leftIcon={<IconPlus />}
                variant="outline">
                {"Бүтээгдэхүүн"}
              </Button>
            )}
        </div>,
        <div key={2}>
          {tabKey === "SUPPLIER" &&
            (user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PRODUCT_MANAGEMENT_SL" && c.isCreate).length > 0 && (
              <Button
                size="sm"
                key={0}
                onClick={() => {
                  navigate("/cf/product/SUPPLIER/new");
                }}
                leftIcon={<IconPlus />}
                variant="outline">
                {"Бүтээгдэхүүн"}
              </Button>
            )}
        </div>,
      ]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs value={tabKey} onTabChange={(e) => onChange(e as string)}>
          <Tabs.List>
            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PRODUCT_MANAGEMENT_BL" && c.isView).length > 0 && (
              <Tabs.Tab value="BUYER" icon={<IconShoppingCart color={tabKey === "BUYER" ? "#3e166e" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Buyer-LED
                </Text>
              </Tabs.Tab>
            )}
            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PRODUCT_MANAGEMENT_SL" && c.isView).length > 0 && (
              <Tabs.Tab value="SUPPLIER" icon={<IconPackage color={tabKey === "SUPPLIER" ? "#3e166e" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Supplier-LED
                </Text>
              </Tabs.Tab>
            )}
          </Tabs.List>
          {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PRODUCT_MANAGEMENT_BL" && c.isView).length > 0 && (
            <Tabs.Panel value="BUYER">{<ProductBuyerLedList />}</Tabs.Panel>
          )}
          {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PRODUCT_MANAGEMENT_SL" && c.isView).length > 0 && (
            <Tabs.Panel value="SUPPLIER">{<ProductSupplierLedList />}</Tabs.Panel>
          )}
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Зээл суурьтай санхүүжилт" },
  {
    label: type,
  },
];
