import React from "react";
import { Badge, Flex, Group, SegmentedControl, Text } from "@mantine/core";
import { PageFilter } from "../layout";
import { Table, ColumnType } from "../table";
import { ProgramApi } from "../../apis";
import { IScfProgram } from "../../interfaces/IScfProgram";
import { IReference } from "../../interfaces/IReference";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { tugrug } from "../../utils";
import { useDebouncedValue } from "@mantine/hooks";

export const ScfFinanceLimitSupplierLedList = () => {
  const [status, setStatus] = React.useState("");
  const [statusDebounced] = useDebouncedValue(status, 400);
  const { programStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    programStatus,
    tugrug,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          break;
        }
        default:
      }
    },
  });

  return (
    <>
      <PageFilter
        left={[
          <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap" key={1}>
            <Text size={14} color="#868E96">
              Хөтөлбөрийн статус
            </Text>
            <SegmentedControl
              key={1}
              onChange={(e) => setStatus(e)}
              value={status}
              data={[{ name: "Бүгд", code: "", color: "yellow" }, ...programStatus].map((item: IReference, index: number) => {
                return {
                  value: item.code || "",
                  label: (
                    <Badge variant="dot" color={item.color} style={{ border: "none" }} key={`${index}`}>
                      {item.name}
                    </Badge>
                  ),
                };
              })}
            />
          </Flex>,
        ]}
      />
      <br />
      <Table
        name="scf.finance.limit.list"
        columns={columns}
        filters={{ query: "", status: statusDebounced, productType: "SUPPLIER_LED" }}
        loadData={(data) => ProgramApi.list(data!)}
      />
    </>
  );
};

const useHeader = ({
  onClick,
  programStatus,
  tugrug,
}: {
  onClick: (key: string, record: IScfProgram) => void;
  programStatus: IReference[];
  tugrug: any;
}): ColumnType<IScfProgram>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Хөтөлбөр #",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хөтөлбөрийн нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хөтөлбөрийн статус",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline" color={(programStatus || []).find((item: IReference) => item.code === record?.programStatus)?.color}>
          {(programStatus || []).find((item: IReference) => item.code === record?.programStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Хөтөлбөрийн лимит",
    sorter: true,
    dataIndex: "programLimit",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.programLimit || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Боломжит лимит",
    sorter: true,
    dataIndex: "availableAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.availableAmount || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Ашигласан лимит",
    sorter: true,
    dataIndex: "utilizedAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.utilizedAmount || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Олголт хүлээж буй",
    sorter: true,
    dataIndex: "toDisburseAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.toDisburseAmount || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Хүсэлт хүлээн авсан",
    sorter: true,
    dataIndex: "disbursedAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.disbursedAmount || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Корпорэйт анкор",
    sorter: true,
    dataIndex: "businessName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.anchor?.partner?.businessName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Анкор бизнес нэр",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.anchor?.profileName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Анкор роль",
    sorter: true,
    dataIndex: "role",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#3e166e">
            {record?.anchor?.refCode || "-"}
          </Text>
          <Text size="sm" weight={500} c="#44566C">
            {record?.anchor?.profileName || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "SME тоо",
    sorter: true,
    dataIndex: "participantCount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.smeCount || "0"}
        </Text>
      );
    },
  },
];
