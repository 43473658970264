import React from "react";
import { PageLayout } from "../../../components/layout";
import { LoadingOverlay, Paper, Tabs, Text } from "@mantine/core";
import { IconCashBanknote, IconFileText, IconHistory } from "@tabler/icons";
import { LbfProductInformationData, ProductBuyerLedInformationForm } from "../../../components/product-buyer/information-form";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";
import { LbfProductApi } from "../../../apis";
import { useNavigate } from "react-router";

export function ProductBuyerLedNew() {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const [tabKey, setTabKey] = React.useState<string | null>("INFORMATION");
  const [loading, setLoading] = React.useState<boolean>(false);

  const onSubmit = async (value: LbfProductInformationData, type: boolean) => {
    setLoading(true);
    try {
      const res = await LbfProductApi.create({
        type: value.type,
        name: value.name,
        resolutionNo: value.resolutionNo,
        description: value.description,
        minInvAmount: value.minInvAmount,
        minOfInv: value.minOfInv,
        maxOfInv: value.maxOfInv,
        minTenor: value.minTenor,
        maxTenor: value.maxTenor,
        financeMinLimit: value.financeMinLimit,
        minDays: value.minDays,
        maxDays: value.maxDays,
        bankProductTypeId: value.bankProductTypeId,
      });
      if (type) {
        navigate(`/cf/product/BUYER/edit/${res.id}/FINANCE`);
      } else {
        navigate("/cf/product/BUYER");
      }
      Message.success("Бүтээгдэхүүнийг амжилттай үүсгэсэн.");
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <PageLayout title="Шинэ бүтээгдэхүүн бүртгэх" subTitle="Buyer-LED SCF бүтээгдэхүүн шинээр бүртгэх" breadcrumb={breadcrumbs} extra={[]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs value={tabKey} onTabChange={setTabKey}>
          <LoadingOverlay visible={loading} />
          <Tabs.List>
            <Tabs.Tab value="INFORMATION" icon={<IconFileText color={tabKey === "INFORMATION" ? "#3e166e" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Үндсэн мэдээлэл
              </Text>
            </Tabs.Tab>
            <Tabs.Tab value="FINANCE" icon={<IconCashBanknote color={tabKey === "FINANCE" ? "#3e166e" : "#44566C"} />} disabled>
              <Text size="sm" weight={500} c="#44566C">
                Хүү, нөхцөл тохиргоо
              </Text>
            </Tabs.Tab>
            <Tabs.Tab value="HISTORY" icon={<IconHistory color={tabKey === "HISTORY" ? "#3e166e" : "#44566C"} />} disabled>
              <Text size="sm" weight={500} c="#44566C">
                Системийн түүх
              </Text>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="INFORMATION">{<ProductBuyerLedInformationForm onSubmit={onSubmit} action={["create"]} />}</Tabs.Panel>
          <Tabs.Panel value="FINANCE">{<></>}</Tabs.Panel>
          <Tabs.Panel value="HISTORY">{<></>}</Tabs.Panel>
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Зээл суурьтай санхүүжилт" },
  {
    label: "Buyer-LED бүтээгдэхүүн",
    to: "/cf/product/BUYER",
  },
  { label: "Шинэ" },
];
