import React from "react";
import { Paper, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { useNavigate, useParams } from "react-router";
import { IconShoppingCart, IconTruckLoading } from "@tabler/icons";
import { ScfFinanceLimitBuyerLedList } from "../../../components/limit-buyer-led/list";
import { ScfFinanceLimitSupplierLedList } from "../../../components/limit-supplier-led/list";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export const ScfFinanceTab = () => {
  const params = useParams();
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [tabKey, setTabKey] = React.useState<string | null>(params.type || "BUYER");

  const title = (type: string) => {
    switch (type) {
      case "BUYER": {
        return "Buyer LED ашиглалтын жагсаалт";
      }
      case "SUPPLIER": {
        return "Supplier LED ашиглалтын жагсаалт";
      }
    }
  };

  const onChange = (value: string) => {
    navigate(`/scf/limit/${value}`);
    setTabKey(value);
  };

  return (
    <PageLayout title={title(tabKey as string)} subTitle="Лимит ашиглалтын жагсаалт" breadcrumb={breadcrumbs} extra={[]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs defaultValue="1" value={tabKey} onTabChange={onChange}>
          <Tabs.List>
            {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isView).length > 0 && (
              <Tabs.Tab icon={<IconShoppingCart color={tabKey === "BUYER" ? "#3e166e" : "#44566C"} />} value="BUYER">
                <Text size="sm" weight={500} c="#44566C">
                  Buyer LED
                </Text>
              </Tabs.Tab>
            )}
            {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_SL" && c.isView).length > 0 && (
              <Tabs.Tab icon={<IconTruckLoading color={tabKey === "SUPPLIER" ? "#3e166e" : "#44566C"} />} value="SUPPLIER">
                <Text size="sm" weight={500} c="#44566C">
                  Supplier LED
                </Text>
              </Tabs.Tab>
            )}
          </Tabs.List>
          {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isView).length > 0 && (
            <Tabs.Panel value="BUYER">
              <ScfFinanceLimitBuyerLedList />
            </Tabs.Panel>
          )}
          {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_SL" && c.isView).length > 0 && (
            <Tabs.Panel value="SUPPLIER">
              <ScfFinanceLimitSupplierLedList />
            </Tabs.Panel>
          )}
        </Tabs>
      </Paper>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "SCF санхүүжилт",
  },
  {
    label: "Лимит ашиглалт",
  },
];
