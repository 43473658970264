import React from "react";
import { Badge, Flex, SegmentedControl, Text } from "@mantine/core";
import { ColumnType, ITableRef, Table } from "../table";
import { LbfReceivableApi } from "../../apis";
import { IReceivableSub } from "../../interfaces/IReceivableSub";
import { IReference } from "../../models/General";
import { IGeneral } from "../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import { tugrug } from "../../utils";
import { PageFilter } from "../layout";
import { useDebouncedValue } from "@mantine/hooks";

export function ReceivableSmeLimit() {
  const ref = React.useRef<ITableRef>(null);
  const { lbfProgramStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const columns = useHeader({ lbfProgramStatus });

  const [program, setProgram] = React.useState("");
  const [programDebounced] = useDebouncedValue(program, 400);

  return (
    <>
      <PageFilter
        left={[
          <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap" key={1}>
            <Text size={14} color="#868E96">
              Хөтөлбөрийн статус
            </Text>
            <SegmentedControl
              key={1}
              onChange={(e) => setProgram(e)}
              value={program}
              data={[{ name: "Бүгд", code: "", color: "yellow" }, ...lbfProgramStatus]?.map((item: IReference, index: number) => {
                return {
                  value: item.code || "",
                  label: (
                    <Badge variant="dot" color={item.color} style={{ border: "none" }} key={`${index}`}>
                      {item.name}
                    </Badge>
                  ),
                };
              })}
            />
          </Flex>,
        ]}
      />

      <Table
        ref={ref}
        name="lbf-receivable-sme-limit.list"
        columns={columns}
        filters={{
          lbfProductType: "",
          status: programDebounced,
        }}
        loadData={(data) => LbfReceivableApi.sub(data!)}
      />
    </>
  );
}

const useHeader = ({ lbfProgramStatus }: { lbfProgramStatus: IReference[] }): ColumnType<IReceivableSub>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "CIF",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.clientCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хөтөлбөрийн нэр",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#3e166e">
            {record?.lbfProgram?.refCode || "-"}
          </Text>
          <Text size="sm" weight={500} c="#44566C">
            {record?.lbfProgram?.name || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Бүтээгдэхүүний нэр",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#3e166e">
            {record?.lbfProgram?.lbfProduct?.refCode || "-"}
          </Text>
          <Text size="sm" weight={500} c="#44566C">
            {record?.lbfProgram?.lbfProduct?.name || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "participantStatus",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          color={(lbfProgramStatus || []).find((item: IReference) => item.code === record?.lbfProgram?.lbfProgramStatus)?.color}>
          {(lbfProgramStatus || []).find((item: IReference) => item.code === record?.lbfProgram?.lbfProgramStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Анкор худалдан авагч",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#3e166e">
            {record?.anchorBusiness?.refCode || "-"}
          </Text>
          <Text size="sm" weight={500} c="#44566C">
            {record?.anchorBusiness?.profileName || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгчийн партнер",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#3e166e">
            {record?.partner?.refCode || "-"}
          </Text>
          <Text size="sm" weight={500} c="#44566C">
            {record?.partner?.businessName || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгчийн бизнес",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#3e166e">
            {record?.refCode || "-"}
          </Text>
          <Text size="sm" weight={500} c="#44566C">
            {record?.profileName || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Баталсан лимит",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record?.subLimit || 0)}
        </Text>
      );
    },
  },
  {
    title: "Боломжит лимит",
    sorter: true,
    dataIndex: "availableAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.availableAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Ашигласан лимит",
    sorter: true,
    dataIndex: "utilizedAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.utilizedAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Хүсэлт гаргасан",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.pendingAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Олголт хүлээж буй",
    sorter: true,
    dataIndex: "toDisburseAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.toDisburseAmount || 0)}
        </Text>
      );
    },
  },
];
