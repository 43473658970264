import { useRef } from "react";
import { Avatar, Badge, Flex, Group, Space, Text } from "@mantine/core";
import { ColumnType, ITableRef, Table } from "../table";
import { tugrug } from "../../utils";
import { CompletedRequestApi } from "../../apis";
import { IFundingRequest } from "../../interfaces/IFundingRequest";
import { IReference } from "../../models/General";
import { dateSecFormat } from "../../utils/date";
import noimage from "../../assets/no-image.png";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";

export const ScfFundingProvidedSupplierList = () => {
  const ref = useRef<ITableRef>(null);
  const { currencies, requestStatus, fundingInstructionStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    currencies,
    requestStatus,
    fundingInstructionStatus,
    tugrug,
  });

  return (
    <>
      <Space h={20} />
      <Table
        ref={ref}
        name="scf.funding.funding.provided.list"
        columns={columns}
        filters={{ query: "", productType: "SUPPLIER_LED", type: "SUPPLIER" }}
        loadData={(data) => CompletedRequestApi.list(data!)}
      />
    </>
  );
};

const useHeader = ({
  requestStatus,
  fundingInstructionStatus,
  tugrug,
  currencies,
}: {
  requestStatus: IReference[];
  fundingInstructionStatus: IReference[];
  tugrug: any;
  currencies: any;
}): ColumnType<IFundingRequest>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Хүсэлтийн #",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 150 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#3e166e">
                {dateSecFormat(record.createdAt)}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес",
    sorter: true,
    dataIndex: "requestedBusiness",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.requestedBusiness?.profileName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#3e166e">
                {record?.requestedBusiness?.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Нийлүүлэгч",
    sorter: true,
    dataIndex: "requestedBusiness",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 150 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record?.requestedBusiness?.partner?.businessName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#3e166e">
                {record?.requestedBusiness?.partner?.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хөтөлбөр нэр",
    sorter: true,
    dataIndex: "program",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 150 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record?.program?.name || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#3e166e">
                {record?.program?.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Бүтээгдэхүүн",
    sorter: true,
    dataIndex: "product",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 200 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record?.product?.name || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#3e166e">
                {record?.product?.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хүсэлтийн дүн",
    sorter: true,
    dataIndex: "requestedAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.requestedAmount || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "currency",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {currencies.find((item: IReference) => item.code === record?.currency)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Хүсэлт төлөв",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(requestStatus || []).find((c: any) => c?.code === record.requestStatus)?.color} 1px solid`,
            color: `${(requestStatus || []).find((c: any) => c?.code === record.requestStatus)?.color}`,
          }}>
          {(requestStatus || []).find((c: IReference) => c.code === record.requestStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Хоног",
    sorter: true,
    dataIndex: "financePeriod",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.financePeriod || "0"}
        </Text>
      );
    },
  },
  {
    title: "Санхүүжилт шимтгэл",
    sorter: true,
    dataIndex: "calculatedFeeAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.calculatedFeeAmount || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Банк шимтгэл",
    sorter: true,
    dataIndex: "bankFeeAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.bankFeeAmount || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх",
    sorter: true,
    dataIndex: "invRefCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.invRefCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх үлдэгдэл",
    sorter: true,
    dataIndex: "invAmountToPay",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.invAmountToPay || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх огноо",
    sorter: true,
    dataIndex: "invCreatedAt",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {dateSecFormat(record.invCreatedAt)}
        </Text>
      );
    },
  },
  {
    title: "Баталсан огноо",
    sorter: true,
    dataIndex: "invConfirmedDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {dateSecFormat(record.invConfirmedDate)}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх баталсан",
    sorter: true,
    dataIndex: "invConfirmedUser",
    render: (record) => {
      return (
        <>
          {
            <Group noWrap>
              <Avatar src={record?.invConfirmedUser?.avatar !== null ? record?.invConfirmedUser?.avatar : noimage} />
              <div style={{ width: 150 }}>
                <Text size="sm" weight={500} c="#44566C">
                  {record?.invConfirmedUser?.lastName || "-"}
                </Text>
                <Flex gap={5} wrap="nowrap">
                  <Text size="sm" weight={500} c="#3e166e">
                    {record?.invConfirmedUser?.firstName || "-"}
                  </Text>
                </Flex>
              </div>
            </Group>
          }
        </>
      );
    },
  },
  {
    title: "Худ.авагч бизнес",
    sorter: true,
    dataIndex: "invReceiverBusiness",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record.type === "SUPPLIER" ? record?.invReceiverBusiness?.profileName : record?.requestedBusiness?.profileName}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#3e166e">
                {record.type === "SUPPLIER" ? record?.invReceiverBusiness?.refCode : record?.requestedBusiness?.refCode}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Худалдан авагч",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record.type === "SUPPLIER" ? record?.invReceiverBusiness?.partner?.businessName : record?.requestedBusiness?.partner?.businessName}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#3e166e">
                {record.type === "SUPPLIER" ? record?.invReceiverBusiness?.partner?.refCode : record?.requestedBusiness?.partner?.refCode}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хүсэлт илгээсэн",
    sorter: true,
    dataIndex: "requestedUser",
    render: (record) => {
      return (
        <>
          {
            <Group noWrap>
              <Avatar src={record?.requestedUser?.avatar !== null ? record?.requestedUser?.avatar : noimage} />
              <div style={{ width: 150 }}>
                <Text size="sm" weight={500} c="#44566C">
                  {record?.requestedUser?.lastName || "-"}
                </Text>
                <Flex gap={5} wrap="nowrap">
                  <Text size="sm" weight={500} c="#3e166e">
                    {record?.requestedUser?.firstName || "-"}
                  </Text>
                </Flex>
              </div>
            </Group>
          }
        </>
      );
    },
  },
  {
    title: "Олголтын төлөв",
    sorter: true,
    dataIndex: "fundingInstructionStatus",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline" color={(fundingInstructionStatus || []).find((item: IReference) => item.code === "DISBURSED")?.color}>
          {(fundingInstructionStatus || []).find((item: IReference) => item.code === "DISBURSED")?.name}
        </Badge>
      );
    },
  },
  {
    title: "Банк ажилтан",
    sorter: true,
    dataIndex: "respondedUser",
    render: (record) => {
      return (
        <>
          {
            <Group noWrap style={{ width: 200 }}>
              <Avatar src={record?.respondedUser?.avatar !== null ? record?.respondedUser?.avatar : noimage} />
              <div style={{ width: 120 }}>
                <Flex gap={5} wrap="nowrap">
                  <Text size="sm" weight={500} c="#44566C">
                    {record?.respondedUser?.lastName || "-"} {record?.respondedUser?.firstName}
                  </Text>
                </Flex>
              </div>
            </Group>
          }
        </>
      );
    },
  },
  {
    title: "Олгосон дүн",
    sorter: true,
    dataIndex: "approvedAmount",
    width: 150,
    render: (record) => {
      return (
        <div style={{ width: 140 }}>
          <Group position="right">
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {tugrug(record.approvedAmount || "0")}
              </Text>
            </Flex>
          </Group>
        </div>
      );
    },
  },
  {
    title: "Шийдвэр огноо",
    sorter: true,
    dataIndex: "respondedDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record?.respondedDate)}
        </Text>
      );
    },
  },
  {
    title: "Олгосон огноо",
    sorter: true,
    dataIndex: "disbursedDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {dateSecFormat(record?.disbursedDate)}
        </Text>
      );
    },
  },
  {
    title: "Эргэн төлөх дүн",
    sorter: true,
    dataIndex: "repaymentAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.repaymentAmount || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Эргэн төлөх огноо",
    sorter: true,
    dataIndex: "repaymentDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record?.repaymentInv?.repaymentDate)}
        </Text>
      );
    },
  },
  {
    title: "Эргэн төлөх нэхэмжлэх",
    sorter: true,
    dataIndex: "repaymentInv",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.repaymentInv?.refCode || "-"}
        </Text>
      );
    },
  },
];
