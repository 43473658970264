import { Badge, ColorSwatch, Divider, Flex, Grid, Group, Paper, Space, Text, createStyles } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { IconArrowDown, IconArrowUp, IconBuilding, IconBuildingCommunity, IconBuildingSkyscraper, IconCalendarEvent, IconCash } from "@tabler/icons";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { dateFormat } from "../../../utils/date";
import { IByPie, IRecentActivated } from "../../../models/ScfDcfDashboard";
import dayjs from "dayjs";

const getOptions = () => ({
  chart: {
    type: "bar",
  },
  title: {
    text: "",
  },
  xAxis: {
    categories: [`Buyer-LED <br/> (SUPPLIER)`, `Buyer-LED <br/> (BUYER)`, `Supplier-LED <br/> (SUPPLIER)`, `Supplier-LED <br/> (BUYER)`],
  },
  yAxis: {
    min: 0,
    title: {
      text: " ",
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    reversed: true,
  },
  colors: ["#9B5DE5", "#F15BB5", "#00BBF9", "#00F5D5"],
  plotOptions: {
    series: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
      },
    },
  },
  series: [
    {
      name: "Ашиглсан лимит",
      data: [4, 4, 6, 15],
    },
    {
      name: "Олголт хүлээж буй",
      data: [5, 3, 12, 6],
    },
    {
      name: "Санхүүжилт хүссэн",
      data: [5, 15, 8, 5],
    },
    {
      name: "Боломжит лимит",
      data: [15, 25, 8, 24],
    },
  ],
});

const recentActivated = [
  {
    name: "АПУ 2024",
    refCode: "SCF-PG-100001",
    anchorName: "Их уул супермаркет",
    anchorRefCode: "PR-100028",
    activatedDate: "2024-03-01T16:00:00.000Z",
  },
  {
    name: "Хүнс 2024",
    refCode: "SCF-PG-100002",
    anchorName: "Апу дэйри нийлүүлэлт",
    anchorRefCode: "3210000",
    activatedDate: "2024-03-04T16:00:00.000Z",
  },
  {
    name: "Барилгын санхүүжилт",
    refCode: "SCF-PG-100005",
    anchorName: "Коф",
    anchorRefCode: "PR-100022",
    activatedDate: "2024-03-10T16:00:00.000Z",
  },
  {
    name: "Барилга нэхэмжлэх суурьтай хөтөлбөр",
    refCode: "SCF-PG-100000",
    anchorName: "Түдэй Монгол",
    anchorRefCode: "PR-100029",
    activatedDate: "2024-03-12T16:00:00.000Z",
  },
];

const pieData = [
  {
    status: "Хянасан, идэвхтэй",
    count: 40,
    color: "#15CAB8",
  },
  {
    status: "Хянасан, идэвхгүй",
    count: 30,
    color: "#44A6E9",
  },
  {
    status: "Үндсэн мэдээлэл хянасан",
    count: 10,
    color: "#FEC600",
  },
  {
    status: "Бүртгэсэн",
    count: 2,
    color: "#FF8548",
  },
  {
    status: "Түр төлөв",
    count: 10,
    color: "#E2362F",
  },
];

export function ScfDcfDashboardStatic({ nowMoment }: { nowMoment: any }) {
  const { classes, theme } = useStyles();

  const stats = [
    {
      changedCount: 20,
      count: 20,
      name: "ИДЭВХТЭЙ ХӨТӨЛБӨР",
      key: 0,
      icon: IconCash,
    },
    { changedCount: 10, count: 10, name: "АНКОР БИЗНЕС", key: 1, icon: IconBuilding },
    { changedCount: 0, count: 0, name: "ЖДБ - SUPPLIER", key: 2, icon: IconBuildingCommunity },
    { changedCount: 20, count: 20, name: "ЖДБ - BUYER", icon: IconBuildingSkyscraper },
  ].map((stat, index) => {
    const DiffIcon = stat.changedCount < 0 ? IconArrowDown : IconArrowUp;

    return (
      <Grid.Col sm={12} md={6} lg={4} xl={3} key={index}>
        <Paper withBorder key={stat.key} radius="md">
          <Group spacing={8} p="sm" noWrap pb="xs">
            <stat.icon color={theme.colors.gray[6]} />
            <Text fz={"sm"} fw={600} color={theme.colors.gray[6]}>
              {stat.name}
            </Text>
          </Group>
          <Group>
            <div className={classes.grdnt}></div>
            <Space />
            <Text fz="xl" fw={500}>
              {stat.count || 0}
            </Text>
          </Group>
          <Group position="right" p={"sm"}>
            <Text size={"xs"} color={theme.colors.gray[6]}>
              Өмнөх өдрөөс
            </Text>
            <Badge color={stat.changedCount < 0 ? "red" : "green"} className={stat.changedCount < 0 ? classes.red : classes.green}>
              <Flex align={"center"} gap={8}>
                <DiffIcon size={"0.8rem"} stroke={3} />
                <Text>
                  {stat.changedCount < 0 && "-"}
                  {Math.abs(stat.changedCount)} %
                </Text>
              </Flex>
            </Badge>
          </Group>
        </Paper>
      </Grid.Col>
    );
  });

  const getPieOptions = () => ({
    title: {
      text: "",
      align: "center",
    },
    subtitle: {
      useHTML: true,
      text: "",
      floating: true,
      verticalAlign: "middle",
      y: 30,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      valueDecimals: 0,
      valueSuffix: " ",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        colorByPoint: true,
        type: "pie",
        size: "100%",
        innerSize: "80%",
        dataLabels: {
          enabled: false,
          crop: false,
          distance: "-10%",
          style: {
            fontWeight: "bold",
            fontSize: "16px",
          },
          connectorWidth: 0,
        },
      },
    },
    series: [
      {
        type: "pie",
        name: "",
        data: (pieData || []).map((i: any) => ({
          name: i?.status,
          y: i?.count,
          color: i?.color,
        })),
      },
    ],
  });

  return (
    <Grid>
      {stats}
      <Grid.Col md={12} lg={8} xl={12}>
        <Paper withBorder py="xs">
          <Flex direction="row" justify="space-between" mb="sm">
            <Group>
              <div className={classes.grdnt}></div>
              <Text size={20} fw={500}>
                Санхүүжилтийн хөтөлбөр лимит
              </Text>
            </Group>

            <Group>
              <DatePicker
                mr={15}
                defaultValue={nowMoment}
                maxDate={nowMoment}
                placeholder="Өдөр сонгох"
                minDate={dayjs(nowMoment).subtract(90, "days").toDate()}
                rightSection={<IconCalendarEvent color="gray" />}
              />
            </Group>
          </Flex>
          <Divider mr={15} ml={15} />
          <HighchartsReact highcharts={Highcharts} options={getOptions()} />
        </Paper>
      </Grid.Col>
      <Grid.Col md={12} lg={8} xl={9}>
        <Paper withBorder py="4px">
          <Group position="apart">
            <Group align="center">
              <div className={classes.grdnt}></div>
              <Text component="h1">Хөтөлбөрийн статус</Text>
            </Group>
            <DatePicker
              mr={15}
              maxDate={nowMoment}
              placeholder="Өдөр сонгох"
              minDate={dayjs(nowMoment).subtract(90, "days").toDate()}
              rightSection={<IconCalendarEvent color="gray" />}
            />
          </Group>
          <Divider mr={15} ml={15} />
          <Flex justify="space-around" align="center">
            <Group w="100%">
              <HighchartsReact highcharts={Highcharts} options={getPieOptions()} />
            </Group>
            <Flex direction="column" gap="sm" w="100%" mr={15}>
              {(pieData || []).map((c: IByPie, index: number) => {
                return (
                  <Group position="apart" key={index} align="start" ml={40}>
                    <Group ml={5}>
                      <ColorSwatch color={c.color} size={6} />
                      <Text fw={500} color="#14182d">
                        {c?.status || "-"}
                      </Text>
                    </Group>
                    <Flex mr={5}>
                      <Text fw={500} color="#557d94">
                        {c?.count || 0}
                      </Text>
                    </Flex>
                  </Group>
                );
              })}
            </Flex>
          </Flex>
        </Paper>
      </Grid.Col>
      <Grid.Col md={12} lg={4} xl={3}>
        <Paper p="sm" withBorder>
          <Text fz="lg" fw={600}>
            Сүүлд идэвхжсэн
          </Text>
          <Divider my="xs" />
          <Flex direction="column" gap="sm">
            {(recentActivated || []).map((c: IRecentActivated, index: number) => {
              return (
                <Group position="apart" key={index} align="start" style={{ background: "#F8F9FA" }}>
                  <Group ml={5}>
                    <div>
                      <Text fw={500} color="#14182d">
                        {c?.anchorName || "-"}
                      </Text>
                      <Text c="#557d94" fw={500} size="sm">
                        {c?.name || "-"}
                      </Text>
                    </div>
                  </Group>
                  <Text fw={500} color="#557d94" mr={5}>
                    {dateFormat(c?.activatedDate)}
                  </Text>
                </Group>
              );
            })}
          </Flex>
        </Paper>
      </Grid.Col>
    </Grid>
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl * 1.5,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  grdnt: {
    background: "#3e166e",
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
  green: {
    borderColor: theme.colors.green[6],
  },
  red: {
    borderColor: theme.colors.red[6],
  },
}));
