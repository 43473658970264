import React from "react";
import { PageLayout } from "../../../components/layout";
import { LoadingOverlay, Paper, Tabs, Text } from "@mantine/core";
import { IconFileText, IconShoppingCart } from "@tabler/icons";
import { FundingProgramSupplierLedInformationForm } from "../../../components/funding-program-supplier/information-form";
import useSwr from "swr";
import { useNavigate, useParams } from "react-router";
import { LbfProductApi, LbfProgramApi } from "../../../apis";
import HttpHandler from "../../../utils/http-handler";
import { Message } from "../../../utils/message";
import { LbfProgramData } from "../../../components/funding-program-buyer/information-form";

export function FundingProgramSupplierLedNew() {
  const params = useParams();
  const { refCode, type } = params;
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb();
  const [tabKey, setTabKey] = React.useState<string | null>(type || "INFORMATION");
  const [loading, setLoading] = React.useState<boolean>(false);

  const { data: productData } = useSwr(`/api/lbf/product/select/${refCode}`, async () => {
    try {
      let res = await LbfProductApi.select({ query: `${refCode}`, type: "SUPPLIER_LED", category: "", limit: "" });
      return res;
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  });

  const onSubmit = async (data: LbfProgramData, type: boolean) => {
    setLoading(true);
    const event = new Date(data.resolutionDate);

    if (type && !data.businessId) {
      Message.error("Та анкорыг сонгон уу!");
    } else {
      try {
        const res = await LbfProgramApi.create({
          type: "CURRENT_ASSET",
          lbfProductId: productData[0]?.id,
          name: data?.name,
          resolutionNo: data?.resolutionNo,
          resolutionDate: event.toISOString(),
          description: data?.description,
          sector1Id: data?.sector1Id,
          sector2Id: data?.sector2Id,
          sector3Id: data?.sector3Id,
          businessId: data?.businessId,
          lbfProgramLimit: parseInt(data?.lbfProgramLimit),
          complete: type,
        });

        if (type) {
          navigate(`/cf/funding-program/SUPPLIER/${refCode}/edit/${res.id}/BUSINESS`);
        } else {
          navigate(`/cf/funding-program/SUPPLIER`);
        }

        Message.success(`Амжилттай зээл суурьтай хөтөлбөр үүсгэн хадгаллаа.`);
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
      }
    }
    setLoading(false);
  };

  return (
    <PageLayout title={"Шинэ хөтөлбөр"} subTitle={"Уламжлалт зээлийн бүтээгдэхүүн суурьтай санхүүжилтийн хөтөлбөр"} breadcrumb={breadcrumbs} extra={[]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs value={tabKey} onTabChange={setTabKey}>
          <LoadingOverlay visible={loading} />
          <Tabs.List>
            <Tabs.Tab value="INFORMATION" icon={<IconFileText color={tabKey === "INFORMATION" ? "#3e166e" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Үндсэн мэдээлэл
              </Text>
            </Tabs.Tab>
            <Tabs.Tab value="BUSINESS" icon={<IconShoppingCart color={tabKey === "BUSINESS" ? "#3e166e" : "#44566C"} />} disabled>
              <Text size="sm" weight={500} c="#44566C">
                Худалдан авагчид
              </Text>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="INFORMATION">{<FundingProgramSupplierLedInformationForm onSubmit={onSubmit} action={["create"]} />}</Tabs.Panel>
          <Tabs.Panel value="BUSINESS">
            <></>
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Зээл суурьтай санхүүжилт" },
  {
    to: "/cf/funding-program/SUPPLIER",
    label: "Supplier-LED хөтөлбөрийн жагсаалт",
  },
  {
    label: "Шинэ",
  },
];
