import React from "react";
import { useRef } from "react";
import { useNavigate } from "react-router";
import { ColumnType, ITableRef, Table } from "../../../components/table";
import { useSelector } from "react-redux";
import { IGeneral } from "../../../interfaces/IGeneral";
import { IScfProgram } from "../../../interfaces/IScfProgram";
import { IconCircleCheck, IconCircleMinus, IconEdit, IconEyeCheck, IconInfoSquare, IconPlus } from "@tabler/icons";
import { Badge, Box, Button, Flex, Group, Modal, Paper, SegmentedControl, Space, Text, Tooltip } from "@mantine/core";
import { tugrug } from "../../../utils";
import { IReference } from "../../../models/General";
import { PageFilter, PageLayout } from "../../../components/layout";
import { ProgramApi } from "../../../apis";
import { useDebouncedValue } from "@mantine/hooks";
import { BuyerProgramRegisterForm } from "../../../components/buyer-led-program/register-form";
import { BuyerLedActiveForm } from "../../../components/buyer-led-program/active-form";
import { BuyerLedInActiveForm } from "../../../components/buyer-led-program/in-active-form";
import { IAuth } from "../../../interfaces/IAuth";
import { IPermissions } from "../../../interfaces/IPermissions";

export function BuyerLedProgramList() {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const refTable = useRef<ITableRef>(null);
  const [action, setAction] = React.useState<any[]>([]);
  const [status, setStatus] = React.useState("");
  const [statusDebounced] = useDebouncedValue(status, 400);
  const [registrationStatus, setRegistrationStatus] = React.useState("");
  const [registrationDebounced] = useDebouncedValue(registrationStatus, 400);
  const { programStatus, programRegistrationStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const onCancel = async () => {
    setAction([]);
  };

  const reload = async () => {
    refTable.current?.reload();
  };

  const columns = useHeader({
    user,
    programRegistrationStatus,
    programStatus,
    onClick: (key, record) => {
      switch (key) {
        case "edit": {
          navigate(`/scf-dcf/dcf-program/${record.product?.refCode}/edit/${record.id}/1`);
          break;
        }
        case "review": {
          navigate(`/scf-dcf/dcf-program/${record.id}/review/1`);
          break;
        }
        case "detail": {
          navigate(`/scf-dcf/dcf-program/${record.id}/detail/1`);
          break;
        }
        case "active": {
          setAction(["active", record]);
          break;
        }
        case "inactive": {
          setAction(["inactive", record]);
          break;
        }
      }
    },
  });

  return (
    <>
      <PageLayout
        title="Buyer LED санхүүжилтийн хөтөлбөр"
        subTitle="Банкны корпорэйт харилцагч нь `Худалдан авагч` ролиор ажиллаж, найдвартай ханган нийлүүлэгчээ дэмжих хөтөлбөр"
        breadcrumb={breadcrumbs}
        extra={[
          <div key={1}>
            {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isCreate).length > 0 && (
              <Button size="sm" key={0} onClick={() => setAction(["new"])} leftIcon={<IconPlus />} variant="outline">
                {"Хөтөлбөр"}
              </Button>
            )}
          </div>,
        ]}>
        <Box
          h="100%"
          sx={(theme) => ({
            border: `1px solid  ${theme.colors.gray[4]}`,
            borderRadius: theme.radius.md,
            backgroundColor: theme.white,
          })}
          px={10}
          py={10}>
          <PageFilter
            left={[
              <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap" key={1}>
                <Text size={14} color="#868E96">
                  Хөтөлбөрийн статус
                </Text>
                <SegmentedControl
                  key={1}
                  onChange={(e) => setStatus(e)}
                  value={status}
                  data={[{ name: "Бүгд", code: "", color: "yellow" }, ...programStatus].map((item: IReference, index: number) => {
                    return {
                      value: item.code || "",
                      label: (
                        <Badge variant="dot" color={item.color} style={{ border: "none" }} key={`${index}`}>
                          {item.name}
                        </Badge>
                      ),
                    };
                  })}
                />
              </Flex>,
              <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap" key={2}>
                <Text size={14} color="#868E96">
                  Бүртгэлийн статус
                </Text>
                <SegmentedControl
                  key={2}
                  onChange={(e) => setRegistrationStatus(e)}
                  value={registrationStatus}
                  data={[{ name: "Бүгд", code: "", color: "yellow" }, ...programRegistrationStatus].map((item: IReference, index: number) => {
                    return {
                      value: item.code || "",
                      label: (
                        <Badge variant="dot" color={item.color} style={{ border: "none" }} key={`${index}`}>
                          {item.name}
                        </Badge>
                      ),
                    };
                  })}
                />
              </Flex>,
            ]}
          />
          <Space h="lg" />
          <Table
            ref={refTable}
            name="buyer-led-program.list"
            columns={columns}
            filters={{ query: "", status: statusDebounced, productType: "BUYER_LED", registrationStatus: registrationDebounced }}
            loadData={(data) => ProgramApi.list(data!)}
          />
        </Box>
      </PageLayout>
      <Modal opened={action && action[0] === "new"} onClose={() => setAction([])} withCloseButton={false} size="lg" centered>
        <BuyerProgramRegisterForm onCancel={onCancel} />
      </Modal>
      <Modal opened={action[0] === "active"} onClose={() => setAction([])} withCloseButton={false} padding={0} size="lg" centered>
        <Paper px="lg">
          <BuyerLedActiveForm reload={reload} onCancel={() => setAction([])} data={action} />
        </Paper>
      </Modal>
      <Modal opened={action[0] === "inactive"} onClose={() => setAction([])} withCloseButton={false} padding={0} size="lg" centered>
        <Paper px="lg">
          <BuyerLedInActiveForm reload={reload} onCancel={() => setAction([])} data={action} />
        </Paper>
      </Modal>
    </>
  );
}

const useHeader = ({
  user,
  onClick,
  programRegistrationStatus,
  programStatus,
}: {
  user: any;
  onClick: (key: string, record: IScfProgram) => void;
  programRegistrationStatus: IReference[];
  programStatus: IReference[];
}): ColumnType<IScfProgram>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex>
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isEdit).length > 0 &&
            record.registrationStatus === "DRAFT" && (
              <Tooltip label="Засах" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
                  <IconEdit />
                </Button>
              </Tooltip>
            )}

          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
              <IconInfoSquare />
            </Button>
          </Tooltip>

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isReview).length > 0 &&
            (record.registrationStatus === "REGISTERED" || record.registrationStatus === "BASIC_INFO_REVIEWED") &&
            record.isCompleted && (
              <Tooltip label="Хянах" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("review", record)}>
                  <IconEyeCheck />
                </Button>
              </Tooltip>
            )}

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_STATUS_CHANGE_BL").length > 0 &&
            record?.registrationStatus === "REVIEWED" &&
            record?.programStatus === "INACTIVE" && (
              <Tooltip label="Идэвхтэй болгох" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("active", record)}>
                  <IconCircleCheck />
                </Button>
              </Tooltip>
            )}

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_STATUS_CHANGE_BL").length > 0 &&
            record?.programStatus === "ACTIVE" && (
              <Tooltip label="Идэвхгүй болгох" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("inactive", record)}>
                  <IconCircleMinus />
                </Button>
              </Tooltip>
            )}
        </Flex>
      );
    },
  },

  {
    title: "Код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 100 }}>
          {record.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хөтөлбөрийн нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бүтээгдэхүүний нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.product?.name || "-"}
          </Text>
          <Text size="sm" weight={500} c="#3e166e">
            {record?.product?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Хөтөлбөр статус",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline" color={(programStatus || []).find((item: IReference) => item.code === record.programStatus)?.color}>
          {(programStatus || []).find((item: IReference) => item.code === record.programStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Анкор партнер",
    sorter: true,
    dataIndex: "category",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.anchor?.partner?.businessName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Анкор бизнесийн нэр",
    sorter: true,
    dataIndex: "unit",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.anchor?.profileName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Анкор санхүү",
    sorter: true,
    dataIndex: "unit",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.anchor?.finUser?.lastName ? record?.anchor?.finUser?.lastName[0] : "-"}. {record?.anchor?.finUser?.firstName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#3e166e">
            {record?.anchor?.finUser?.email || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Хөтөлбөрийн лимит",
    sorter: true,
    dataIndex: "programLimit",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.programLimit || 0)}</Group>
        </Text>
      );
    },
  },
  {
    title: "Бүртгэсэн sme",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.smeCount || 0}
        </Text>
      );
    },
  },
  {
    title: "Хянасан sme",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.reviewedSmeCount || 0}
        </Text>
      );
    },
  },
  {
    title: "Идэвхтэй sme",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.activeSmeCount || 0}
        </Text>
      );
    },
  },
  {
    title: "Sme",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.reviewPendingSmeCount || 0}
        </Text>
      );
    },
  },
  {
    title: "Бүртгэлийн статус",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          color={(programRegistrationStatus || []).find((item: IReference) => item.code === record.registrationStatus)?.color}>
          {(programRegistrationStatus || []).find((item: IReference) => item.code === record.registrationStatus)?.name}
        </Badge>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Санхүүжилтийн хөтөлбөр",
  },
  {
    label: "Buyer LED хөтөлбөр",
  },
];
