import React from "react";
import useSwr from "swr";
import { useNavigate, useParams } from "react-router";
import { ProgramApi } from "../../../apis";
import { PageLayout } from "../../../components/layout";
import { Button, LoadingOverlay, Paper, Tabs, Text } from "@mantine/core";
import { BuyerLedProgramReviewInformationForm } from "../../../components/buyer-led-program/review-information-form";
import { BuyerLedProgramReviewCustomerForm } from "../../../components/buyer-led-program/review-customer-form";
import { BuyerLedHistoryList } from "../../../components/buyer-led-program/history-list";
import { IconFileText, IconHistory, IconTruckLoading } from "@tabler/icons";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function BuyerLedProgramDetail() {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const params = useParams();
  const { step } = params;
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [tabKey, setTabKey] = React.useState<string>((step as string) || "1");
  const [loading, setLoading] = React.useState<boolean>(false);

  const { data, isLoading, mutate } = useSwr(`/api/program/buyer-led/${params.id}/detail`, async () => await ProgramApi.get(params.id!), {
    fallbackData: null,
  });

  const onTabChange = (_index: string) => {
    navigate(`/scf-dcf/dcf-program/${params.id}/detail/${_index}`);
    setTabKey(_index);
  };

  return (
    <PageLayout
      title={tabKey === "1" ? "Үндсэн мэдээлэл дэлгэрэнгүй" : "Анкор партнерийн мэдээлэл"}
      subTitle={tabKey === "1" ? "SCF хөтөлбөрны үндсэн мэдээлэл дэлгэрэнгүй" : "Сонгосон анкор партнерийн мэдээлэл"}
      breadcrumb={breadcrumbs}
      bgColor="#F8F9FA"
      extra={[
        <Button variant="outline" key="back" onClick={() => navigate(`/scf-dcf/dcf-program`)}>
          {"Буцах"}
        </Button>,
      ]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs
          onTabChange={(e: string) => {
            onTabChange(e);
          }}
          value={tabKey}
          defaultValue={tabKey}>
          <Tabs.List>
            <Tabs.Tab value="1" icon={<IconFileText color={tabKey === "1" ? "#3e166e" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Хөтөлбөрийн мэдээлэл
              </Text>
            </Tabs.Tab>
            {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_BL" && c.isView).length > 0 && (
              <Tabs.Tab value="2" icon={<IconTruckLoading color={tabKey === "2" ? "#3e166e" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Нийлүүлэгч бизнесүүд
                </Text>
              </Tabs.Tab>
            )}
            {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isView).length > 0 && (
              <Tabs.Tab value="3" icon={<IconHistory color={tabKey === "3" ? "#3e166e" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Системийн түүх
                </Text>
              </Tabs.Tab>
            )}
          </Tabs.List>
          {isLoading || loading ? (
            <LoadingOverlay visible />
          ) : (
            <>
              <Tabs.Panel value="1">
                <BuyerLedProgramReviewInformationForm
                  data={data}
                  reload={async () => {
                    setLoading(true);
                    await mutate();
                    setTabKey("2");
                    setLoading(false);
                  }}
                  basicSector={(data?.programSectors || []).filter((c: any) => c.level === 1)}
                  sector={(data?.programSectors || []).filter((c: any) => c.level === 2)}
                  subSector={(data?.programSectors || []).filter((c: any) => c.level === 3)}
                />
              </Tabs.Panel>
              {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_BL" && c.isView).length > 0 && (
                <Tabs.Panel value="2">
                  <BuyerLedProgramReviewCustomerForm
                    data={data}
                    reload={async () => {
                      setLoading(true);
                      await mutate();
                      setLoading(false);
                    }}
                  />
                </Tabs.Panel>
              )}
              {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isView).length > 0 && (
                <Tabs.Panel value="3">
                  <BuyerLedHistoryList />
                </Tabs.Panel>
              )}
            </>
          )}
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Санхүүжилтийн хөтөлбөр",
  },
  {
    to: "/scf-dcf/dcf-program",
    label: "Buyer LED хөтөлбөр",
  },
  {
    label: "Хөтөлбөр дэлгэрэнгүй",
  },
];
