import React from "react";
import { Paper, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { useNavigate, useParams } from "react-router-dom";
import { ScfFundingRepaymentList } from "../../../components/scf-repayment/list";
import { ScfFundingRepaymentSupplierLedList } from "../../../components/scf-repayment/supplier-led-list";
import { IconShoppingCart, IconTruckLoading } from "@tabler/icons";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export const ScfFinanceRepayment = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [tabKey, setTabKey] = React.useState<string | null>(params.type || "FINANCE");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const title = (type: string) => {
    switch (type) {
      case "FINANCE": {
        return "Supplier LED эргэн төлөлтийн жагсаалт";
      }
      case "PAY": {
        return "Дараа төлөх эргэн төлөлтийн жагсаалт";
      }
      case "SUPPLIER": {
        return "Supplier LED эргэн төлөлтийн жагсаалт";
      }
    }
  };

  const onChange = (value: string) => {
    navigate(`/scf/finance-refund-paid/${value}`);
    setTabKey(value);
  };

  const breadcrumbs = useBreadcrumb();

  return (
    <PageLayout title={title(tabKey as string)} subTitle="Харилцагчдын эргэн төлөх нэхэмжлэхийн жагсаалт" breadcrumb={breadcrumbs} extra={[]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs value={tabKey} onTabChange={(e) => onChange(e as string)}>
          <Tabs.List>
            {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_REPAYMENT_BL").length > 0 && (
              <Tabs.Tab value="FINANCE" icon={<IconShoppingCart color={tabKey === "FINANCE" ? "#3e166e" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Buyer LED
                </Text>
              </Tabs.Tab>
            )}
            {/* <Tabs.Tab value="PAY" icon={<IconCreditCard color={tabKey === "PAY" ? "#3e166e" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Дараа төлөх
              </Text>
            </Tabs.Tab> */}

            {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_REPAYMENT_SL").length > 0 && (
              <Tabs.Tab value="SUPPLIER" icon={<IconTruckLoading color={tabKey === "SUPPLIER" ? "#3e166e" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Supplier LED
                </Text>
              </Tabs.Tab>
            )}
          </Tabs.List>
          {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_REPAYMENT_BL").length > 0 && (
            <Tabs.Panel value="FINANCE">
              <ScfFundingRepaymentList />
            </Tabs.Panel>
          )}
          {/* <Tabs.Panel value="PAY">
            <ScfFundingRepaymentBuyerList />
          </Tabs.Panel> */}
          {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_REPAYMENT_SL").length > 0 && (
            <Tabs.Panel value="SUPPLIER">
              <ScfFundingRepaymentSupplierLedList />
            </Tabs.Panel>
          )}
        </Tabs>
      </Paper>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "SCF санхүүжилт",
  },
  {
    label: "Эргэн төлөлтүүд",
  },
];
