import { Badge, Divider, Flex, Grid, Group, Paper, Space, Text, createStyles } from "@mantine/core";
import { IconArrowDown, IconArrowUp, IconBuilding, IconCalendar, IconCalendarEvent, IconCash, IconHomeInfinity, IconMoneybag } from "@tabler/icons";
import { tugrug } from "../../../utils";
import { DatePicker, DateRangePicker } from "@mantine/dates";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const getOptions = () => ({
  chart: {
    type: "bar",
  },
  title: {
    text: "",
  },
  xAxis: {
    categories: [`Buyer-LED <br/> (SUPPLIER)`, `Buyer-LED <br/> (BUYER)`, `Supplier-LED <br/> (SUPPLIER)`, `Supplier-LED <br/> (BUYER)`],
  },
  yAxis: {
    min: 0,
    title: {
      text: " ",
    },
  },
  legend: {
    reversed: true,
  },
  colors: ["#890E34", "#EB0404", "#FF540D", "#FF76A1", "#FF9900", "#25B475"],
  plotOptions: {
    series: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
      },
    },
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      name: ">90 хоног",
      data: [15, 25, 8, 24],
    },
    {
      name: "61-90 хоног",
      data: [15, 25, 8, 24],
    },
    {
      name: "31-60 хоног",
      data: [15, 25, 8, 24],
    },
    {
      name: "4-31 хоног",
      data: [5, 15, 8, 5],
    },
    {
      name: "1-3 хоног",
      data: [5, 3, 12, 6],
    },
    {
      name: "хугацаа",
      data: [4, 4, 6, 15],
    },
  ],
});

const getOptionsColumn = () => ({
  chart: {
    zoomType: "xy",
  },
  title: {
    text: "",
    align: "left",
  },
  subtitle: {
    text: "",
    align: "left",
  },
  xAxis: [
    {
      categories: [
        "2023-10-26",
        "2023-10-27",
        "2023-10-28",
        "2023-10-29",
        "2023-10-30",
        "2023-10-31",
        "2023-11-01",
        "2023-11-02",
        "2023-11-03",
        "2023-11-04",
        "2023-11-05",
        "2023-11-06",
      ],
      crosshair: true,
    },
  ],
  yAxis: [
    {
      labels: {
        format: "{value}",
        style: {
          color: "gray",
        },
      },
      title: {
        text: "",
        style: {
          color: "green",
        },
      },
    },
    {
      title: {
        text: "",
        style: {
          color: "black",
        },
      },
      labels: {
        format: "{value}",
        style: {
          color: "gray",
        },
      },
      opposite: true,
    },
  ],
  tooltip: {
    shared: true,
  },
  legend: {
    align: "rigth",
    y: 18,
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      name: "Тоо",
      color: "#904bee",
      type: "column",
      data: [50, 200, 400, 260, 50, 60, 20, 40, 99, 150, 80, 280],
      tooltip: {
        valueSuffix: "",
      },
    },
    {
      name: "Төлөгдсөн",
      type: "spline",
      color: "#904bee",
      data: [20, 10, 30, 40, 4, 6, 2, 0, 9, 10, 0, 100],
      tooltip: {
        valueSuffix: "",
      },
    },
  ],
});

export function CollectionDashboardStatic({ nowMoment }: { nowMoment: Date }) {
  const { classes, theme } = useStyles();

  const stats = [
    {
      changedCount: 16,
      count: 27,
      name: "АНХААРАХ ХАРИЛЦАГЧ",
      key: 0,
      icon: IconCash,
      type: "number",
    },
    { changedCount: 10, count: 22400, name: "ХУГАЦАА ХЭТЭРСЭН ДҮН (мян ₮)", key: 1, icon: IconBuilding, type: "currency" },
    { changedCount: 9, count: 22, name: "НЭХЭМЖЛЭХ (тоо)", key: 2, icon: IconHomeInfinity, type: "number" },
    { changedCount: 20, count: 150303, name: "ТӨЛӨГДСӨН ДҮН (мян ₮)", icon: IconMoneybag, type: "currency" },
  ].map((stat, index) => {
    const DiffIcon = stat.changedCount < 0 ? IconArrowDown : IconArrowUp;

    return (
      <Grid.Col sm={12} md={6} lg={4} xl={3} key={index}>
        <Paper withBorder key={stat.key} radius="md">
          <Group spacing={8} p="sm" noWrap pb="xs">
            <stat.icon color={theme.colors.gray[6]} />
            <Text fz={"sm"} fw={600} color={theme.colors.gray[6]}>
              {stat.name}
            </Text>
          </Group>
          <Group>
            <div className={classes.grdnt}></div>
            <Space />
            <Text fz="xl" fw={500}>
              {stat.type === "currency" ? tugrug(stat.count || 0) : stat.count}
            </Text>
          </Group>
          <Group position="right" p={"sm"}>
            <Text size={"xs"} color={theme.colors.gray[6]}>
              Өмнөх өдрөөс
            </Text>
            <Badge color={stat.changedCount < 0 ? "red" : "green"} className={stat.changedCount < 0 ? classes.red : classes.green}>
              <Flex align={"center"} gap={8}>
                <DiffIcon size={"0.8rem"} stroke={3} />
                <Text>
                  {stat.changedCount < 0 && "-"}
                  {Math.abs(stat.changedCount)} %
                </Text>
              </Flex>
            </Badge>
          </Group>
        </Paper>
      </Grid.Col>
    );
  });

  return (
    <Grid>
      {stats}
      <Grid.Col md={12} lg={8} xl={12}>
        <Paper withBorder py="xs">
          <Flex direction="row" justify="space-between" mb="sm">
            <Group>
              <div className={classes.grdnt}></div>
              <Text size={20} fw={500}>
                Санхүүжилтийн хүсэлт
              </Text>
            </Group>

            <Group>
              <DatePicker mr={15} defaultValue={nowMoment} placeholder="Өдөр сонгох" rightSection={<IconCalendarEvent color="gray" />} />
            </Group>
          </Flex>
          <Divider mr={15} ml={15} />
          <HighchartsReact highcharts={Highcharts} options={getOptions()} />
        </Paper>
      </Grid.Col>
      <Grid.Col md={12} lg={8} xl={12}>
        <Paper withBorder py={"xs"}>
          <Flex justify="space-between">
            <Group>
              <div className={classes.grdnt}></div>
              <Text component="h1" ml={10}>
                Санхүүжилтийн хүсэлт
              </Text>
            </Group>
            <Group>
              <DateRangePicker
                defaultValue={[nowMoment, nowMoment]}
                style={{ borderRadius: "5px" }}
                variant="filled"
                maw={"300px"}
                w={"100%"}
                mr={15}
                bg={theme.colors.blue[0]}
                placeholder="Огноо хайх"
                icon={<IconCalendar size={"1.2rem"} />}
                inputFormat="YYYY-MM-DD"
              />
            </Group>
          </Flex>
          <Paper px={"lg"}>
            <HighchartsReact highcharts={Highcharts} options={getOptionsColumn()} />
            <Group position="right" style={{ marginTop: "-24px" }}>
              <Text fz={"sm"} color="dimmed" bg={"white"} style={{ zIndex: 2 }}>
                Хэмжих нэгж: Сая төгрөгөөр
              </Text>
            </Group>
            <Space h={"xs"} />
          </Paper>
        </Paper>
      </Grid.Col>
    </Grid>
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl * 1.5,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  grdnt: {
    background: "#3e166e",
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
  green: {
    borderColor: theme.colors.green[6],
  },
  red: {
    borderColor: theme.colors.red[6],
  },
}));
