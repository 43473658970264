import React, { useRef } from "react";
import { Avatar, Badge, Button, Drawer, Flex, Paper, Text, Tooltip } from "@mantine/core";
import { ColumnType, ITableRef, Table } from "../../../components/table";
import { FinanceableApi } from "../../../apis";
import { IconInfoSquare } from "@tabler/icons";
import { dateSecFormat } from "../../../utils/date";
import { tugrug } from "../../../utils";
import { PageLayout } from "../../../components/layout";
import { IFinancePotential } from "../../../interfaces/IFinancePotential";
import { ScfFundingPotentialDetail } from "./detail";
import noimage from "../../../assets/no-image.png";
import { useSelector } from "react-redux";
import { IGeneral } from "../../../interfaces/IGeneral";
import { IReference } from "../../../models/General";

export const ScfFinancePotentialList = () => {
  const ref = useRef<ITableRef>(null);
  const breadcrumbs = useBreadcrumb();
  const [action, setAction] = React.useState<string[]>([]);
  const { invoicePaymentStatus, invoiceStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    tugrug,
    invoicePaymentStatus,
    invoiceStatus,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["detail", record.id]);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  return (
    <>
      <PageLayout title="Боломжит нэхэмжлэл" subTitle="Санхүүжих боломжтой нэхэмжлэхийн жагсаалт." breadcrumb={breadcrumbs} extra={[]}>
        <Paper radius="sm" p="md" withBorder>
          <Table
            ref={ref}
            name="scf.finance.potential.list"
            columns={columns}
            filters={{ query: "", type: "" }}
            loadData={(data) => FinanceableApi.list(data!)}
          />
        </Paper>
        <Drawer
          opened={action[0] === "detail"}
          onClose={() => {
            setAction([]);
          }}
          position="right"
          withCloseButton={false}
          styles={{ drawer: { overflowY: "scroll" } }}
          size="80%">
          <ScfFundingPotentialDetail action={action} onCancel={onCancel} />
        </Drawer>
      </PageLayout>
    </>
  );
};

const useHeader = ({
  onClick,
  invoicePaymentStatus,
  tugrug,
  invoiceStatus,
}: {
  onClick: (key: string, record: IFinancePotential) => void;
  invoicePaymentStatus: IReference[];
  invoiceStatus: IReference[];
  tugrug: any;
}): ColumnType<IFinancePotential>[] => [
  {
    title: "Үйлдэл",
    dataIndex: "description",
    render: (record) => {
      return (
        <Tooltip label="Дэлгэрэнгүй" position="bottom">
          <Button variant="light" radius={100} w={30} h={30} p={0} onClick={() => onClick("detail", record)}>
            <IconInfoSquare />
          </Button>
        </Tooltip>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Нэхэмжлэх #",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => (
      <Flex direction="column" style={{ width: 150 }}>
        <Text size="sm" weight={500} c="#44566C">
          {record.refCode}
        </Text>
        <Text size="sm" weight={500} c="#3e166e">
          {dateSecFormat(record?.createdAt)}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Борлуулалтын захиалга",
    sorter: true,
    dataIndex: "salesOrder",
    width: 240,
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.salesOrder?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Худалдан авагчийн захиалга",
    sorter: true,
    dataIndex: "purchaseOrder",
    width: 290,
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.purchaseOrder?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нийлүүлэгчийн бизнес",
    sorter: true,
    dataIndex: "senderBusiness",
    render: (record) =>
      record?.type === "SALES" ? (
        <Flex direction="column">
          <Text size="sm" weight={500} c="#44566C">
            {record?.senderBusiness?.profileName}
          </Text>
          <Text size="sm" weight={500} c="#3e166e">
            {record?.senderBusiness?.refCode}
          </Text>
        </Flex>
      ) : (
        <Flex direction="column">
          <Text size="sm" weight={500} c="#44566C">
            {record?.receiverBusiness?.profileName}
          </Text>
          <Text size="sm" weight={500} c="#3e166e">
            {record?.receiverBusiness?.refCode}
          </Text>
        </Flex>
      ),
    width: 230,
  },
  {
    title: "Нийлүүлэгч",
    sorter: true,
    dataIndex: "senderBusiness",
    render: (record) =>
      record?.type === "SALES" ? (
        <Flex direction="column">
          <Text size="sm" weight={500} c="#44566C">
            {record?.senderBusiness?.partner.businessName}
          </Text>
          <Text size="sm" weight={500} c="#3e166e">
            {record?.senderBusiness?.partner?.refCode}
          </Text>
        </Flex>
      ) : (
        <Flex direction="column">
          <Text size="sm" weight={500} c="#44566C">
            {record?.receiverBusiness?.partner?.businessName}
          </Text>
          <Text size="sm" weight={500} c="#3e166e">
            {record?.receiverBusiness?.partner?.refCode}
          </Text>
        </Flex>
      ),
    width: 130,
  },
  {
    title: "Баталсан дүн",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {tugrug(record.confirmedAmount || "0")}
        </Text>
      );
    },
  },
  {
    title: "Төлсөн дүн",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {tugrug(record?.paidAmount || "0")}
        </Text>
      );
    },
  },
  {
    title: "Үлдэгдэл",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {tugrug(record?.amountToPay || "-")}
        </Text>
      );
    },
  },
  {
    title: "Төлбөр статус",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(invoicePaymentStatus || []).find((c: any) => c?.code === record?.paymentStatus)?.color} 1px solid`,
            color: `${(invoicePaymentStatus || []).find((c: any) => c?.code === record?.paymentStatus)?.color}`,
          }}>
          {(invoicePaymentStatus || []).find((item: IReference) => item.code === record?.paymentStatus)?.name}
        </Badge>
      );
    },
    width: 230,
  },
  {
    title: "Хугацаа",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.remainingDays}
        </Text>
      );
    },
    width: 430,
  },
  {
    title: "Баталсан хугацаа",
    sorter: true,
    dataIndex: "description",
    render: (record) => (
      <Text size="sm" weight={500} c="#44566C">
        {dateSecFormat(record.confirmedDate)}
      </Text>
    ),
    width: 430,
  },
  {
    title: "Нэхэмжлэх огноо",
    sorter: true,
    dataIndex: "description",
    render: (record) => (
      <Text size="sm" weight={500} c="#44566C">
        {dateSecFormat(record.createdAt)}
      </Text>
    ),
    width: 360,
  },
  {
    title: "Нэхэмжлэх баталсан",
    sorter: true,
    dataIndex: "description",
    render: (record) => (
      <Flex gap="xs" align="center">
        <Avatar
          radius={100}
          src={record?.confirmedUser !== null ? (record?.confirmedUser?.avatar !== null ? record?.confirmedUser?.avatar : noimage) : noimage}
        />
        <Flex direction="column" gap="xs">
          <Text size="sm" weight={500} c="#44566C">
            {record?.confirmedUser !== null ? (record?.confirmedUser?.lastName || "-", record?.confirmedUser?.firstName || "-") : "-"}
          </Text>
        </Flex>
      </Flex>
    ),
    width: 490,
  },
  {
    title: "Нэхэмжлэх статус",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(invoiceStatus || []).find((c: any) => c?.code === record?.invoiceStatus)?.color} 1px solid`,
            color: `${(invoiceStatus || []).find((c: any) => c?.code === record?.invoiceStatus)?.color}`,
          }}>
          {(invoiceStatus || []).find((item: IReference) => item.code === record?.invoiceStatus)?.name}
        </Badge>
      );
    },
    width: 130,
  },
  {
    title: "Худалдан авагч бизнес",
    sorter: true,
    dataIndex: "description",
    render: (record) =>
      record?.type === "SALES" ? (
        <Flex direction="column">
          <Text size="sm" weight={500} c="#44566C">
            {record?.receiverBusiness?.profileName}
          </Text>
          <Text size="sm" weight={500} c="#3e166e">
            {record?.receiverBusiness?.refCode}
          </Text>
        </Flex>
      ) : (
        <Flex direction="column" gap="xs">
          <Text size="sm" weight={500} c="#44566C">
            {record?.senderBusiness?.profileName}
          </Text>
          <Text size="sm" weight={500} c="#3e166e">
            {record?.senderBusiness?.refCode}
          </Text>
        </Flex>
      ),
    width: 430,
  },
  {
    title: "Худалдан авагч",
    sorter: true,
    dataIndex: "description",
    render: (record) =>
      record?.type === "SALES" ? (
        <Flex direction="column">
          <Text size="sm" weight={500} c="#44566C">
            {record?.receiverBusiness?.partner?.businessName}
          </Text>
          <Text size="sm" weight={500} c="#3e166e">
            {record?.receiverBusiness?.partner?.refCode}
          </Text>
        </Flex>
      ) : (
        <Flex direction="column" gap="xs">
          <Text size="sm" weight={500} c="#44566C">
            {record?.senderBusiness?.partner?.businessName}
          </Text>
          <Text size="sm" weight={500} c="#3e166e">
            {record?.senderBusiness?.partner?.refCode}
          </Text>
        </Flex>
      ),

    width: 430,
  },
  {
    title: "Илгээсэн ажилтан",
    sorter: true,
    dataIndex: "description",
    render: (record) => (
      <Flex gap="xs" align="center">
        <Avatar radius={100} src={record?.senderUser !== null ? (record?.senderUser?.avatar !== null ? record?.senderUser?.avatar : noimage) : noimage} />
        <Flex direction="column" gap="xs">
          <Text size="sm" weight={500} c="#44566C">
            {record?.senderUser !== null ? (record?.senderUser?.lastName || "-", record?.senderUser?.firstName || "-") : "-"}
          </Text>
        </Flex>
      </Flex>
    ),
    width: 530,
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "SCF санхүүжилт",
  },
  {
    label: "Боломжит нэхэмжлэл",
  },
];
