import React from "react";
import * as yup from "yup";
import { FormLayout } from "../layout";
import { Alert, Avatar, CloseButton, Grid, Group, Input, Paper, ScrollArea, Space, Tabs, Text, Table as TableCore, Flex, Divider, Button } from "@mantine/core";
import { useParams } from "react-router";
import { IconAlertCircle, IconBackspace, IconPhoto } from "@tabler/icons";
import { Form } from "../form";
import { tugrug } from "../../utils";
import noimage from "../../assets/no-image.png";
import { NumberCurrencyField } from "../form/number-currency-field";
import { Message } from "../../utils/message";
import HttpHandler from "../../utils/http-handler";
import { dateTimeFormat } from "../../utils/date";
import { LbfParticipantApi } from "../../apis";
import { FundingProgramSupplierLedSmeStep } from "./sme-step";
// import { FundingProgramBuyerLedSmeStep } from "./sme-step";

const schema = yup.object({
  smes: yup
    .array()
    .of(
      yup.object().shape({
        subLimit: yup.number().min(1, " ").typeError(" ").required(" ").nullable(),
      }),
    )
    .min(1, "Заавал бөглөнө!")
    .required("Заавал бөглөнө!")
    .nullable(),
});

export function FundingProgramSuplierLedSmeForm({ action, reload }: { action: any; reload: () => void }) {
  const params = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [step, setStep] = React.useState<string>("1");
  const [data, setFormData] = React.useState<any>({
    smes: [],
    ...(action[0] === "sme_add" && action[1]),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      await LbfParticipantApi.add(`${params?.id}`, {
        smes: values.smes.map((item: any) => ({
          smeBusinessId: item?.smeBusinessId,
          subLimit: item?.subLimit,
        })),
      });
      Message.success(`Таны хүсэлт амжилттай . ${dateTimeFormat(new Date())}`);
      reload();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const decre = (value: any[]) => {
    let itemsTotalAmount = value?.reduce((total, item) => total + item?.subLimit || 0, 0);
    return itemsTotalAmount;
  };

  return (
    <FormLayout
      title="Анкорын хамтрагч Supplier бизнесүүд"
      subTitle="Анкорын хамтрагч Supplier бизнесүүд"
      my={0}
      extra={[<CloseButton key="cancel" onClick={() => reload()} />]}>
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {({ values, setFieldValue }) => {
          return (
            <div>
              <div>
                <Tabs value={step} defaultValue="gallery">
                  <Tabs.Panel value="1" pt="xs">
                    <Paper>
                      <Paper radius="sm" p="lg" withBorder>
                        <Grid>
                          <Grid.Col md={6} lg={4}>
                            <Input.Wrapper label="Анкор партнерийн нэр: ">
                              <Text color="indigo">
                                {values?.anchor?.partner?.businessName || "-"}, #{values?.anchor?.partner?.refCode || "-"}
                              </Text>
                            </Input.Wrapper>
                          </Grid.Col>
                          <Grid.Col md={6} lg={4}>
                            <Input.Wrapper label="Анкор бизнесийн нэр: ">
                              <Text color="indigo">
                                {values?.anchor?.profileName || "-"} #{values?.anchor?.refCode || "-"}
                              </Text>
                            </Input.Wrapper>
                          </Grid.Col>
                          <Grid.Col md={6} lg={4}>
                            <Input.Wrapper label="Хөтөлбөр дэх анкорын роль: ">
                              <Text color="indigo">{values?.anchor?.refCode?.match("BU") ? "Худалдан авагч" : "Нийлүүлэгч"}</Text>
                            </Input.Wrapper>
                          </Grid.Col>
                        </Grid>
                      </Paper>
                      <Space h={"lg"} />
                      <Alert
                        icon={<IconAlertCircle size={16} />}
                        styles={{ message: { color: "#44566c", fontWeight: 500 } }}
                        color="indigo"
                        radius="md"
                        withCloseButton={false}>
                        Анкор бизнестэй хамтран ажилладаг гэрээт нийлүүлэгч "{`${values?.anchor?.refCode}, ${values?.anchor?.profileName}`}" байна. Та доорх
                        жагсаалтаас сонголт хийнэ үү. Ханган нийлүүлэгч олдохгүй бол SME бизнесийг урих, эсвэл анкорыг SME бизнесээ DeHUB Network-д урихыг
                        зөвлөх хэрэгтэй.
                      </Alert>
                      <Space h={"lg"} />
                      <Input.Wrapper
                        label={`SME бизнесийг сонгох, хайх`}
                        description={`Та ${values?.smes?.length || 0} Supplier бизнес сонгосон байна. `}
                        required>
                        <Space h={"xs"} />
                        {action[1]?.anchor?.businessId && (
                          <FundingProgramSupplierLedSmeStep
                            filters={{ businessId: action[1]?.anchor?.businessId, lbfProgramId: params.id }}
                            setSelectedSmes={(selecteds: any) => {
                              setFormData({
                                smes: selecteds,
                              });

                              const smes = selecteds.reduce((accumulator: any, iterator: any) => {
                                const sme = values.smes?.find((s: any) => s.smeBusinessId === iterator.id);

                                return [
                                  ...accumulator,
                                  {
                                    smeBusinessId: iterator?.id,
                                    subLimit: sme?.subLimit,
                                    smeFinUserId: sme?.smeFinUserId,
                                    anchorFinUserId: sme?.anchorFinUserId,
                                  },
                                ];
                              }, [] as any[]);

                              setFieldValue("smes", {
                                smes: smes,
                              });
                            }}
                            values={values}
                          />
                        )}
                      </Input.Wrapper>
                    </Paper>
                  </Tabs.Panel>

                  <Tabs.Panel value="2" pt="xs">
                    <div>
                      <Paper radius="sm" p="lg" withBorder>
                        <Grid>
                          <Grid.Col md={6} lg={4}>
                            <Input.Wrapper label="Хөтөлбөрт бүртгэсэн нийлүүлэгч тоо: ">
                              <Text color="indigo">{values?.smes?.length || 0}</Text>
                            </Input.Wrapper>
                          </Grid.Col>
                          <Grid.Col md={6} lg={4}>
                            <Input.Wrapper label="Дэд лимитийн нийт дүн: ">
                              <Text color="indigo">{tugrug(decre(values?.smes))}</Text>
                            </Input.Wrapper>
                          </Grid.Col>
                          <Grid.Col md={6} lg={4}></Grid.Col>
                        </Grid>
                      </Paper>
                      <Space h={"xl"} />
                      <Input.Wrapper
                        label="Дэд лимит болон харьцаж ажиллах дансыг тохируулна уу."
                        description={`Та ${values?.smes?.length || 0}  бизнес сонгосон байна. `}
                        required>
                        <ScrollArea style={{ width: "100%", zIndex: 0, minHeight: "400px" }}>
                          <TableCore captionSide="bottom">
                            <thead>
                              <tr>
                                <th>
                                  <Flex w="20px">№</Flex>
                                </th>
                                <th>
                                  <Flex w="50px">CIF</Flex>
                                </th>
                                <th>
                                  <Flex w="220px">ТТД</Flex>
                                </th>
                                <th>
                                  <Flex w="220px">Партнер нэр</Flex>
                                </th>
                                <th>
                                  <Flex w="220px">Худалдан авагчийн нэр</Flex>
                                </th>
                                <th>
                                  <Flex w="220px">Лимитийн мөнгөн дүн</Flex>
                                </th>
                                <th>
                                  <Flex w="220px">Худалдан авагчийн санхүү</Flex>
                                </th>
                                <th>
                                  <Flex w="220px">Худалдан авагчийн орлогын данс</Flex>
                                </th>
                                <th>
                                  <Flex w="220px">Худалдан авагчийн зарлагын данс</Flex>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.smes?.map((item: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <Text size="sm" weight={500} c="#44566C">
                                        {index + 1}
                                      </Text>
                                    </td>
                                    <td>
                                      <Text size="sm" weight={500} c="#44566C">
                                        {item?.clientCode || "-"}
                                      </Text>
                                    </td>
                                    <td>
                                      <Text size="sm" weight={500} c="#44566C">
                                        {item?.regNumber || "-"}
                                      </Text>
                                    </td>
                                    <td>
                                      <Flex align="center" gap={8} w={200}>
                                        <Text w={220}>
                                          <Text size="sm" weight={500} c="#868E96">
                                            {item.partnerName || "-"}
                                          </Text>
                                          <Text size="sm" weight={500} c="#44566C">
                                            {item.partnerRefCode || "-"}
                                          </Text>
                                        </Text>
                                      </Flex>
                                    </td>
                                    <td>
                                      <Flex align="center" gap={8} w={200}>
                                        <Text w={220}>
                                          <Text size="sm" weight={500} c="#868E96">
                                            {item.profileName || "-"}
                                          </Text>
                                          <Text size="sm" weight={500} c="#44566C">
                                            # {item.refCode || "-"}
                                          </Text>
                                        </Text>
                                      </Flex>
                                    </td>
                                    <td>
                                      <NumberCurrencyField
                                        precision={2}
                                        size="xs"
                                        name={`smes[${index}].subLimit`}
                                        placeholder="0"
                                        noError={true}
                                        max={999999999999}
                                      />
                                    </td>
                                    <td>
                                      <Flex align="center" gap={8} w={400}>
                                        <Avatar src={item?.finUser?.avatar !== null ? item?.finUser?.avatar : noimage} size={42}>
                                          <IconPhoto />
                                        </Avatar>
                                        <Text w={300}>
                                          <Text size="sm" weight={500} c="#868E96">
                                            {item?.finUser?.lastName ? item?.finUser?.lastName[0] : ""}
                                            {item?.finUser?.lastName ? "." : ""} {item?.finUser?.firstName || "-"}
                                          </Text>
                                          <Text size="sm" weight={500} c="#44566C">
                                            {item?.finUser?.email || "-"}
                                          </Text>
                                        </Text>
                                      </Flex>
                                    </td>
                                    <td>
                                      <Flex align="center" gap={8} w={200}>
                                        <Avatar src={item?.smeInAcc?.icon !== null ? item?.smeInAcc?.icon : noimage} size={42}>
                                          <IconPhoto />
                                        </Avatar>
                                        <Text w={220}>
                                          <Text size="sm" weight={500} c="#3e166e">
                                            {item?.smeInAcc?.bankName || "-"}
                                          </Text>
                                          <Text size="sm" weight={500} c="#44566C">
                                            {item?.smeInAcc?.number || "-"}
                                          </Text>
                                        </Text>
                                      </Flex>
                                    </td>
                                    <td>
                                      <Flex align="center" gap={8} w={200}>
                                        <Avatar src={item?.smeOutAcc?.icon !== null ? item?.smeOutAcc?.icon : noimage} size={42}>
                                          <IconPhoto />
                                        </Avatar>
                                        <Text w={220}>
                                          <Text size="sm" weight={500} c="#3e166e">
                                            {item?.smeOutAcc?.bankName || "-"}
                                          </Text>
                                          <Text size="sm" weight={500} c="#44566C">
                                            {item?.smeOutAcc?.number || "-"}
                                          </Text>
                                        </Text>
                                      </Flex>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </TableCore>
                        </ScrollArea>
                      </Input.Wrapper>
                    </div>
                  </Tabs.Panel>
                </Tabs>
                <Divider />
                <Group position="right" mt={"md"}>
                  {step === "2" ? (
                    <>
                      <Button
                        onClick={() => {
                          setStep("1");
                        }}
                        variant="light"
                        disabled={loading}
                        key={2}
                        leftIcon={<IconBackspace />}>
                        Буцах
                      </Button>
                      <Button loading={loading} key={3} type="submit">
                        Болсон
                      </Button>
                    </>
                  ) : (
                    <Button
                      disabled={!values?.smes?.length}
                      onClick={() => {
                        setStep("2");
                      }}
                      loading={loading}
                      key={1}>
                      Хөтөлбөрт хамруулах
                    </Button>
                  )}
                </Group>
              </div>
            </div>
          );
        }}
      </Form>
    </FormLayout>
  );
}
