import React from "react";
import { ActionIcon, Paper, Space, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../layout";
import { IconAlarm, IconFileText, IconMailForward, IconUserExclamation, IconX } from "@tabler/icons";
import { PaymentReminderHistoryList } from "./history-list";
import { PaymentObservationActionLogList } from "./action-log-list";
import { PaymentObservationMailList } from "./mail-list";

export const PaymentInformationOpenInvoiceTabs = ({ action, setAction, onCancel }: { action: any; setAction: (e: any) => void; onCancel: () => void }) => {
  const [tabIndex, setTabIndex] = React.useState<string | null>("1");

  const onTabChange = (_index: any) => {
    setTabIndex(_index);
  };

  return (
    <>
      <PageLayout
        title={`${action[1]?.invReceiverBusiness?.refCode} ${action[1]?.invReceiverBusiness?.profileName}`}
        extra={[
          <ActionIcon onClick={() => onCancel()} key={1}>
            <IconX />
          </ActionIcon>,
        ]}>
        <Paper radius="sm" p="md" withBorder>
          <Tabs defaultValue="1" value={tabIndex} onTabChange={onTabChange}>
            <Tabs.List>
              <Tabs.Tab icon={<IconFileText color={tabIndex === "1" ? "#3e166e" : "#44566C"} />} value="1">
                <Text size="sm" weight={500} c="#44566C">
                  Үйл явцын лог
                </Text>
              </Tabs.Tab>
              <Tabs.Tab icon={<IconMailForward color={tabIndex === "2" ? "#3e166e" : "#44566C"} />} value="2">
                <Text size="sm" weight={500} c="#44566C">
                  Имэйл мэдэгдэл
                </Text>
              </Tabs.Tab>
              <Tabs.Tab icon={<IconAlarm color={tabIndex === "3" ? "#3e166e" : "#44566C"} />} value="3">
                <Text size="sm" weight={500} c="#44566C">
                  Сануулга түүх
                </Text>
              </Tabs.Tab>
              <Tabs.Tab icon={<IconUserExclamation color={tabIndex === "4" ? "#3e166e" : "#44566C"} />} value="4">
                <Text size="sm" weight={500} c="#44566C">
                  Авлага хариуцсан
                </Text>
              </Tabs.Tab>
            </Tabs.List>
            <Space h={10} />
            <Tabs.Panel value="1">{tabIndex === "1" && <PaymentObservationActionLogList action={action} />}</Tabs.Panel>
            <Tabs.Panel value="2">{tabIndex === "2" && <PaymentObservationMailList action={action} />}</Tabs.Panel>
            <Tabs.Panel value="3">{tabIndex === "3" && <PaymentReminderHistoryList action={action} />}</Tabs.Panel>
            <Tabs.Panel value="4">
              <></>
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </PageLayout>
    </>
  );
};
