import { Badge, ColorSwatch, Divider, Flex, Grid, Group, Paper, Space, Text, createStyles } from "@mantine/core";
import { IconArrowDown, IconArrowUp, IconBuilding, IconBuildingCommunity, IconBuildingSkyscraper, IconCalendarEvent, IconCash } from "@tabler/icons";
import { IByPie, INumberSurvey, IProgramLimit, IRecentActivated } from "../../../models/ScfDcfDashboard";
import { IScfDcfDashboard } from "../../../interfaces/IScfDcfDashboard";
import { DatePicker } from "@mantine/dates";
import { dateFormat } from "../../../utils/date";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";
import { IScfByInterval } from "../../../models/ScfDashboard";
import { IScfProgram } from "../../../interfaces/IScfProgram";

export function ScfDcfDashboardReal({
  data,
  limitDate,
  setLimitDate,
  nowMoment,
  pieDate,
  setPieDate,
}: {
  // data: IScfDcfDashboard;
  data: any;
  limitDate: Date | null;
  setLimitDate: any;
  nowMoment: any;
  pieDate: Date | null;
  setPieDate: any;
}) {
  const { classes, theme } = useStyles();

  const getPieOptions = () => ({
    title: {
      text: "",
      align: "center",
    },
    subtitle: {
      useHTML: true,
      text: "",
      floating: true,
      verticalAlign: "middle",
      y: 30,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      valueDecimals: 0,
      valueSuffix: " ",
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        colorByPoint: true,
        type: "pie",
        size: "100%",
        innerSize: "80%",
        dataLabels: {
          enabled: false,
          crop: false,
          distance: "-10%",
          style: {
            fontWeight: "bold",
            fontSize: "16px",
          },
          connectorWidth: 0,
        },
      },
    },
    series: [
      {
        type: "pie",
        name: "",
        data: (data?.byPie || []).map((i: any) => ({
          name: i?.status,
          y: i?.count,
          color: i?.color,
        })),
      },
    ],
  });

  const getOptionsStacked = () => ({
    chart: {
      type: "bar",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: (data?.programLimit || []).map((c: any) => c.name),
    },
    yAxis: {
      min: 0,
      title: {
        text: " ",
      },
    },
    legend: {
      reversed: true,
    },
    colors: ["#9068de", "#e26bb1", "#43baf4", "#66f0d7"],
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
    },
    credits: {
      enabled: false,
    },
    // "utilizedAmount": 1265200, ashig
    // "disbursePendingAmount": 1228800, olgolt
    // "requestedAmount": 30459000, hussen
    // "availableAmount": 205734804 bolomjit
    series: [
      {
        name: "Ирсэн",
        color: "#9068de",
        type: "column",
        data: (data?.programLimit?.amounts || []).map((c: any) => {
          return c.count;
        }),
        // data: [],
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Зөвшөөрсөн",
        color: "#e26bb1",
        type: "column",
        // data: (data?.byRow[1]?.amounts || []).map((c: any) => {
        //   return c.count;
        // }),
        data: [],
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Олголт хийж дууссан",
        color: "#43baf4",
        type: "column",
        // data: (data?.byRow[2]?.amounts || []).map((c: any) => {
        //   return c.count;
        // }),
        data: [],
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Татгалзсан",
        color: "#66f0d7",
        type: "column",
        // data: (data?.byRow[3]?.amounts || []).map((c: any) => {
        //   return c.count;
        // }),
        data: [],
        tooltip: {
          valueSuffix: "",
        },
      },
    ],
  });

  return (
    <Grid>
      {(data?.numberSurvey || []).map((item: INumberSurvey, index: number) => {
        const DiffIcon = item.changedCount < 0 ? IconArrowDown : IconArrowUp;

        return (
          <Grid.Col sm={12} md={6} lg={4} xl={3} key={index}>
            <Paper withBorder radius="md">
              <Group spacing={8} p="sm" noWrap pb="xs">
                {index === 0 && <IconCash color={theme.colors.gray[6]} />}
                {index === 1 && <IconBuilding color={theme.colors.gray[6]} />}
                {index === 2 && <IconBuildingCommunity color={theme.colors.gray[6]} />}
                {index === 3 && <IconBuildingSkyscraper color={theme.colors.gray[6]} />}
                <Text fz="sm" fw={600} color={theme.colors.gray[6]}>
                  {item.name || "-"}
                </Text>
              </Group>
              <Group>
                <div className={classes.grdnt}></div>
                <Space />
                <Text fz="xl" fw={500}>
                  {item.count || 0}
                </Text>
              </Group>
              <Group position="right" p={"sm"}>
                <Text size={"xs"} color={theme.colors.gray[6]}>
                  Өмнөх өдрөөс
                </Text>
                <Badge color={item.changedCount < 0 ? "red" : "green"} className={item.changedCount < 0 ? classes.red : classes.green}>
                  <Flex align={"center"} gap={8}>
                    <DiffIcon size={"0.8rem"} stroke={3} />
                    <Text>
                      {item.changedCount < 0 && "-"}
                      {Math.abs(item.changedCount)} %
                    </Text>
                  </Flex>
                </Badge>
              </Group>
            </Paper>
          </Grid.Col>
        );
      })}

      <Grid.Col md={12} lg={8} xl={12}>
        <Paper withBorder py="xs">
          <Flex direction="row" justify="space-between" mb="sm">
            <Group>
              <div className={classes.grdnt}></div>
              <Text size={20} fw={500}>
                Санхүүжилтийн хөтөлбөр лимит
              </Text>
            </Group>
            <Group>
              <DatePicker
                key={1}
                value={limitDate ? limitDate : nowMoment}
                maxDate={nowMoment || limitDate}
                onChange={setLimitDate}
                placeholder="Өдөр сонгох"
                minDate={dayjs(nowMoment || limitDate)
                  .subtract(14, "days")
                  .toDate()}
                rightSection={<IconCalendarEvent color="gray" />}
              />
            </Group>
          </Flex>
          <Divider mr={15} ml={15} />
          <HighchartsReact highcharts={Highcharts} options={getOptionsStacked()} />
        </Paper>
      </Grid.Col>

      <Grid.Col md={12} lg={8} xl={9}>
        <Paper withBorder py="4px">
          <Group position="apart">
            <Group align="center">
              <div className={classes.grdnt}></div>
              <Text component="h1">Хөтөлбөрийн статус</Text>
            </Group>
            <DatePicker
              mr={15}
              value={pieDate ? pieDate : nowMoment}
              maxDate={nowMoment || pieDate}
              onChange={setPieDate}
              placeholder="Өдөр сонгох"
              minDate={dayjs(nowMoment || pieDate)
                .subtract(90, "days")
                .toDate()}
              rightSection={<IconCalendarEvent color="gray" />}
            />
          </Group>
          <Divider mr={15} ml={15} />
          <Flex justify="space-around" align="center">
            <Group w="100%">
              <HighchartsReact highcharts={Highcharts} options={getPieOptions()} />
            </Group>
            <Flex direction="column" gap="sm" w="100%" mr={15}>
              {(data?.byPie || []).map((c: IByPie, index: number) => {
                return (
                  <Group position="apart" key={index} align="start" ml={40}>
                    <Group ml={5}>
                      <ColorSwatch color={c.color} size={6} />
                      <Text fw={500} color="#14182d">
                        {c?.status || "-"}
                      </Text>
                    </Group>
                    <Flex mr={5}>
                      <Text fw={500} color="#557d94">
                        {c?.count || 0}
                      </Text>
                    </Flex>
                  </Group>
                );
              })}
            </Flex>
          </Flex>
        </Paper>
      </Grid.Col>
      <Grid.Col md={12} lg={4} xl={3}>
        <Paper p="sm" withBorder>
          <Text fz="lg" fw={600}>
            Сүүлд идэвхжсэн
          </Text>
          <Divider my="xs" />
          <Flex direction="column" gap="sm">
            {(data?.recentActivated || []).map((c: IRecentActivated, index: number) => {
              return (
                <Group position="apart" key={index} align="start" style={{ background: "#F8F9FA" }}>
                  <Group ml={5}>
                    <div>
                      <Text fw={500} color="#14182d">
                        {c?.anchorName || "-"}
                      </Text>
                      <Text c="#557d94" fw={500} size="sm">
                        {c?.name || "-"}
                      </Text>
                    </div>
                  </Group>
                  <Text fw={500} color="#557d94" mr={5}>
                    {dateFormat(c?.activatedDate)}
                  </Text>
                </Group>
              );
            })}
          </Flex>
        </Paper>
      </Grid.Col>
    </Grid>
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl * 1.5,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  grdnt: {
    background: "#3e166e",
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
  green: {
    borderColor: theme.colors.green[6],
  },
  red: {
    borderColor: theme.colors.red[6],
  },
}));
