import React, { useRef } from "react";
import { Avatar, Badge, Button, CloseButton, Divider, Flex, Input, LoadingOverlay, Modal, Paper, Text, Tooltip } from "@mantine/core";
import { FormLayout } from "../layout";
import { IReference } from "../../models/General";
import { IParticipant } from "../../interfaces/IParticipant";
import { ColumnType, Table } from "../table";
import { IconBrandTelegram, IconCircleCheck, IconCircleMinus, IconEyeCheck, IconPhoto, IconPlus, IconTrash } from "@tabler/icons";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import noimage from "../../assets/no-image.png";
import { tugrug } from "../../utils";
import { LbfParticipantApi } from "../../apis";
import { ILbfProgram } from "../../interfaces/ILbfProgram";
import { Dialog } from "../../utils/confirm-modal";
import { Message } from "../../utils/message";
import HttpHandler from "../../utils/http-handler";
import { FundingProgramSuplierLedSmeForm } from "./sme-form";
import { FundingProgramBuyerLedReviewForm } from "../funding-program-buyer/review-form";
import { IPermissions } from "../../interfaces/IPermissions";
import { IAuth } from "../../interfaces/IAuth";

export function FundingProgramSupplierLedBusinessList({ data, onSuccess }: { data: ILbfProgram; onSuccess: () => void }) {
  const { lbfParticipantStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [action, setAction] = React.useState<String[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const ref = useRef<any>(null);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const columns = useHeader({
    user,
    lbfParticipantStatus,
    onClick: (key: string, item: IParticipant) => {
      switch (key) {
        case "edit": {
          break;
        }

        case "review": {
          setAction(["review", item as any]);
          break;
        }
        case "to_review": {
          setAction(["to_review", item as any]);
          break;
        }
        case "active": {
          Dialog.confirm("Та уг бизнесийг идэвхгүй болгох гэж байна итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setIsLoading(true);
                try {
                  await LbfParticipantApi.status({ lbfProgramId: item.lbfProgramId, smeBusinessId: item.smeBusinessId, isActive: false });
                  Message.success("Амжилттай идэвхгүй болгосон.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                reload();
                setIsLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }
        case "in_active": {
          Dialog.confirm("Та уг бизнесийг идэвхтэй болгох гэж байна итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setIsLoading(true);
                try {
                  await LbfParticipantApi.status({ lbfProgramId: item.lbfProgramId, smeBusinessId: item.smeBusinessId, isActive: true });
                  Message.success("Амжилттай идэвхтэй болгосон.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                reload();
                setIsLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }

        case "remove": {
          Dialog.confirm("Та уг бизнесийг устгахдаа илгээхдээ итгэлтэй байна уу. Та итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setIsLoading(true);
                try {
                  await LbfParticipantApi.remove(item.id, item.smeBusinessId);
                  Message.success("Амжилттай устгасан.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                reload();
                setIsLoading(false);
                break;
              }

              default:
            }
          });

          break;
        }
      }
    },
  });

  const reload = async () => {
    await ref.current?.reload();
  };

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return (
    <>
      <FormLayout
        title="Худалдан авагч"
        subTitle="Анкор бизнестэй харилцдаг нийлүүлэгчид"
        extra={[
          <>
            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_SME_MANAGEMENT_SL").length > 0 && (
              <Button
                variant="outline"
                onClick={() => {
                  setAction(["sme_add", data as any]);
                }}
                key="sme_add"
                disabled={!data?.anchor?.businessId}
                leftIcon={<IconPlus />}>
                Худалдан авагч
              </Button>
            )}
          </>,
        ]}>
        <Paper radius="sm" p="lg" withBorder>
          <Flex gap={8}>
            <Input.Wrapper label="Хөтөлбөрт бүртгэсэн нийлүүлэгч тоо:">
              <Text fw={500} color="indigo" fz="sm">
                {`${data?.smeCount || 0} бизнес`}
              </Text>
            </Input.Wrapper>
            <Input.Wrapper label="Дэд лимитийн нийт дүн:">
              <Text fw={500} color="indigo" fz="sm">
                {tugrug(data?.smeTotalLimit || 0)}
              </Text>
            </Input.Wrapper>
          </Flex>
          <Divider h="lg" mt="lg" />
          <Table
            ref={ref}
            name="lbf-supplier-led-program-business.list"
            filters={{ productType: "SUPPLIER_LED", lbfProgramId: data?.id }}
            columns={columns}
            loadData={(data) => LbfParticipantApi.list(data!)}
          />
        </Paper>
      </FormLayout>
      <Modal opened={action[0] === "sme_add"} onClose={() => setAction([])} withCloseButton={false} centered size="80%">
        <FundingProgramSuplierLedSmeForm
          action={action}
          reload={() => {
            reload();
            onSuccess && onSuccess();
            setAction([]);
          }}
        />
      </Modal>
      <Modal opened={action[0] === "review"} onClose={() => setAction([])} withCloseButton={false} size="lg" centered>
        <FormLayout
          title="Оролцогч бизнесийг хянах"
          subTitle="Хөтөлбөрт оролцогч бизнесийг хянаж зөвшөөрөх"
          my={0}
          extra={[
            <CloseButton
              key="cancel"
              onClick={() => {
                setAction([]);
              }}
            />,
          ]}>
          <FundingProgramBuyerLedReviewForm
            data={action[1]}
            success={() => {
              reload();
            }}
            onCancel={() => setAction([])}
            type="PARTICIPANT-REVIEW"
          />
        </FormLayout>
      </Modal>

      <Modal opened={action[0] === "to_review"} onClose={() => setAction([])} withCloseButton={false} size="lg" centered>
        <FormLayout
          title="Хяналтад илгээх"
          subTitle="Хөтөлбөрт оролцогч бизнесийг хянуулах"
          my={0}
          extra={[
            <CloseButton
              key="cancel"
              onClick={() => {
                setAction([]);
              }}
            />,
          ]}>
          <FundingProgramBuyerLedReviewForm
            data={action[1]}
            success={() => {
              reload();
            }}
            onCancel={() => setAction([])}
            type="PARTICIPANT-TO_REVIEW"
          />
        </FormLayout>
      </Modal>
    </>
  );
}

const useHeader = ({
  user,
  onClick,
  lbfParticipantStatus,
}: {
  user: any;
  lbfParticipantStatus: IReference[];
  onClick: (key: string, record: IParticipant) => void;
}): ColumnType<IParticipant>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PROGRAM_SME_MANAGEMENT_SL" && c.isReview).length > 0 &&
            record?.lbfParticipantStatus === "IN_REVIEW" &&
            record?.lbfProgramStatusRegistrationStatus === "BASIC_INFO_REVIEWED" && (
              <Tooltip label="Хянах" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("review", record)}>
                  <IconEyeCheck />
                </Button>
              </Tooltip>
            )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PROGRAM_SME_MANAGEMENT_SL" && c.isReview).length > 0 &&
            record?.lbfParticipantStatus === "IN_REVIEW" &&
            record?.lbfProgramStatusRegistrationStatus === "REVIEWED" && (
              <Tooltip label="Хянах" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("review", record)}>
                  <IconEyeCheck />
                </Button>
              </Tooltip>
            )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PROGRAM_SME_MANAGEMENT_SL" && c.isEdit).length > 0 &&
            record?.lbfParticipantStatus === "REGISTERED" &&
            record?.lbfProgramStatus !== "CLOSED" &&
            record?.lbfProgramStatus !== "CANCELLED" && (
              <Tooltip label="Хяналтад илгээх" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("to_review", record)}>
                  <IconBrandTelegram />
                </Button>
              </Tooltip>
            )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PROGRAM_SME_STATUS_CHANGE_SL").length > 0 &&
            record?.lbfParticipantStatus !== "REGISTERED" &&
            record?.lbfParticipantStatus !== "IN_REVIEW" &&
            record?.lbfParticipantStatus !== "ACTIVE" &&
            record?.lbfProgramStatus === "ACTIVE" && (
              <Tooltip label="Идэвхтэй болгох" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("in_active", record)}>
                  <IconCircleCheck />
                </Button>
              </Tooltip>
            )}

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PROGRAM_SME_STATUS_CHANGE_SL").length > 0 &&
            record?.lbfParticipantStatus !== "REGISTERED" &&
            record?.lbfParticipantStatus !== "IN_REVIEW" &&
            record?.lbfParticipantStatus !== "INACTIVE" &&
            record?.lbfProgramStatus === "ACTIVE" && (
              <Tooltip label="Идэвхгүй болгох" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("active", record)}>
                  <IconCircleMinus />
                </Button>
              </Tooltip>
            )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PROGRAM_SME_MANAGEMENT_SL" && c.isDelete).length > 0 &&
            record?.lbfParticipantStatus === "REGISTERED" && (
              <Tooltip label="Устгах" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record)}>
                  <IconTrash />
                </Button>
              </Tooltip>
            )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "CIF",
    dataIndex: "clientCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.clientCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "ТТД",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Партнер нэр",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.partner?.logo !== null ? record?.partner?.logo : noimage} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#3e166e">
              {record?.partner?.refCode || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.partner?.businessName || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Худалдан авагчийн бизнес",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Text w={220}>
            <Text size="sm" weight={500} c="#3e166e">
              {record?.refCode || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.profileName || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Худалдан авагчийн дэд лимит",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record?.availableAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Худалдан авагчийн санхүү",
    sorter: true,
    dataIndex: "partnerName",
    render: (record) => {
      return (
        <Flex align="center" gap={8}>
          <Avatar src={record?.finUser?.avatar !== null ? record?.finUser?.avatar : noimage} size={42}>
            <IconPhoto />
          </Avatar>
          <Text w={250}>
            <Text size="sm" weight={500} c="#44566C">
              {record?.finUser?.lastName ? record?.finUser?.lastName[0].toUpperCase() : ""}
              {record?.finUser?.lastName ? "." : ""} {record?.finUser?.firstName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.finUser?.email || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Статус",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(lbfParticipantStatus || []).find((c: any) => c?.code === record.lbfParticipantStatus)?.color} 1px solid`,
            color: `${(lbfParticipantStatus || []).find((c: any) => c?.code === record.lbfParticipantStatus)?.color}`,
          }}>
          {(lbfParticipantStatus || [])?.find((item: IReference) => item.code === record.lbfParticipantStatus)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Худалдан авагчийн орлогын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.smeInAcc?.icon !== null ? record?.smeInAcc?.icon : noimage} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#3e166e">
              {record?.smeInAcc?.bankName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.smeInAcc?.number || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Худалдан авагчийн зарлагын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.smeOutAcc?.icon !== null ? record?.smeOutAcc?.icon : noimage} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#3e166e">
              {record?.smeOutAcc?.bankName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.smeOutAcc?.number || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Анкорын орлогын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.anchorInAcc?.icon !== null ? record?.anchorInAcc?.icon : noimage} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#3e166e">
              {record?.anchorInAcc?.bankName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.anchorInAcc?.number || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Анкорын зарлагын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.anchorOutAcc?.icon !== null ? record?.anchorOutAcc?.icon : noimage} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#3e166e">
              {record?.anchorOutAcc?.bankName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.anchorOutAcc?.number || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
];
