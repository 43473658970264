import React from "react";
import { PageLayout } from "../../../components/layout";
import { CloseButton, Paper, Tabs, Text } from "@mantine/core";
import { ScfProductDetailInformationForm } from "../../../components/supplier-product/detail-information-form";
import { ScfProductDetailFinanceForm } from "../../../components/supplier-product/detail-finance-form";
import { HistoryList } from "../../../components/history/list";
import { IconFileText, IconHistory, IconMoneybag } from "@tabler/icons";
import { IAuth } from "../../../interfaces/IAuth";
import { useSelector } from "react-redux";

export function SupplierProductDetail({ action, reload, onCancel }: { action: any; reload: () => void; onCancel: () => void }) {
  const [tabIndex, setTabIndex] = React.useState("1");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const onTabChange = (values: string) => {
    setTabIndex(values);
  };

  return (
    <>
      <PageLayout
        title={`Бүтээгдэхүүний код ${action[1]?.refCode}`}
        subTitle="Supplier LED-SCF бүтээгдэхүүний дэлгэрэнгүй"
        bgColor="#F8F9FA"
        extra={[
          <CloseButton
            key="cancel"
            onClick={() => {
              onCancel();
            }}
          />,
        ]}>
        <Paper radius="sm" p="md" withBorder>
          <Tabs defaultValue="1" value={tabIndex} onTabChange={(e) => onTabChange(e as string)}>
            <Tabs.List>
              <Tabs.Tab value="1" icon={<IconFileText color={tabIndex === "1" ? "#3e166e" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Үндсэн мэдээлэл
                </Text>
              </Tabs.Tab>
              <Tabs.Tab value="2" icon={<IconMoneybag color={tabIndex === "2" ? "#9F20F7" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Санхүүжилтийн тохиргоо
                </Text>
              </Tabs.Tab>
              {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_MANAGEMENT_SL" && c.isView).length > 0 && (
                <Tabs.Tab value="3" icon={<IconHistory color={tabIndex === "3" ? "#3e166e" : "#44566C"} />}>
                  <Text size="sm" weight={500} c="#44566C">
                    Системийн түүх
                  </Text>
                </Tabs.Tab>
              )}
            </Tabs.List>
            <Tabs.Panel value="1">
              <ScfProductDetailInformationForm action={action} onSuccess={() => setTabIndex("2")} onCancel={onCancel} />
            </Tabs.Panel>
            <Tabs.Panel value="2">
              <ScfProductDetailFinanceForm action={action} onSuccess={() => setTabIndex("1")} onCancel={onCancel} reload={reload} />
            </Tabs.Panel>
            {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_MANAGEMENT_SL" && c.isView).length > 0 && (
              <Tabs.Panel value="3">
                <HistoryList data={action && action[1]?.id} />
              </Tabs.Panel>
            )}
          </Tabs>
        </Paper>
      </PageLayout>
    </>
  );
}
