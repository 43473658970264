import React from "react";
import { PageLayout } from "../../../components/layout";
import { CloseButton, Paper, Tabs, Text } from "@mantine/core";
import { IconCashBanknote, IconFileText, IconHistory } from "@tabler/icons";
import { ProductBuyerLedHistoryList } from "../../../components/product-buyer/history-list";
import { ProductBuyerLedInformationDetail } from "../../../components/product-buyer/information-detail";
import { ProductBuyerLedFinanceDetail } from "../../../components/product-buyer/finance-detail";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function ProductBuyerLedDetail({ onCancel, action, reload }: { onCancel: () => void; action: any; reload: () => void }) {
  const [tabKey, setTabKey] = React.useState<string | null>("INFORMATION");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  return (
    <PageLayout
      title={`Бүтээгдэхүүний код ${action[1]?.refCode}`}
      subTitle="Buyer-LED SCF бүтээгдэхүүний дэлгэрэнгүй"
      extra={[<CloseButton key="cancel" onClick={() => onCancel()} />]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs value={tabKey} onTabChange={setTabKey}>
          <Tabs.List>
            <Tabs.Tab value="INFORMATION" icon={<IconFileText color={tabKey === "INFORMATION" ? "#3e166e" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Үндсэн мэдээлэл
              </Text>
            </Tabs.Tab>
            <Tabs.Tab value="FINANCE" icon={<IconCashBanknote color={tabKey === "FINANCE" ? "#3e166e" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Хүү, нөхцөл тохиргоо
              </Text>
            </Tabs.Tab>

            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PRODUCT_MANAGEMENT_BL" && c.isView).length > 0 && (
              <Tabs.Tab value="HISTORY" icon={<IconHistory color={tabKey === "HISTORY" ? "#3e166e" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Системийн түүх
                </Text>
              </Tabs.Tab>
            )}
          </Tabs.List>
          <Tabs.Panel value="INFORMATION">
            {<ProductBuyerLedInformationDetail data={action && action[1]} back={onCancel} next={() => setTabKey("FINANCE")} />}
          </Tabs.Panel>
          <Tabs.Panel value="FINANCE">
            {
              <ProductBuyerLedFinanceDetail
                action={action}
                data={action && action[1]}
                back={() => setTabKey("INFORMATION")}
                next={() => setTabKey("HISTORY")}
                onSuccess={() => {
                  onCancel();
                  reload();
                }}
              />
            }
          </Tabs.Panel>
          {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PRODUCT_MANAGEMENT_BL" && c.isView).length > 0 && (
            <Tabs.Panel value="HISTORY">{action[1] && <ProductBuyerLedHistoryList id={action[1]?.id} />}</Tabs.Panel>
          )}
        </Tabs>
      </Paper>
    </PageLayout>
  );
}
